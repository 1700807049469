import React from "react"

import {Link} from "react-router-dom";
import General from "../utils/General";
import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import Email from "../utils/Email";

const MODE_SIGNIN = "signin"
const MODE_FORGOT_PASSWORD = "forgot_password"
const MODE_RESET_PASSWORD = "reset_password"

export default class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mode: MODE_SIGNIN,
    }
  }

  componentDidMount() {
    document.getElementById("root").className = "bg-body"
  }

  _isFormValid() {
    let { email, password, confirmPassword, mode, code } = this.state

    let error = null
    if (!Email.isValid(email)) {
      error = 'Please enter a valid email address'
    }

    if(mode === MODE_RESET_PASSWORD){
      if(!code){
        error = 'Please enter a valid code'
      }
      if(!/^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/.test(password)){
        error = "Please ensure your password contains letters, numbers and is at least 8 characters long"
      }
      if (password !== confirmPassword) {
        error = "Please ensure both passwords match"
      }
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _handleResetPassword(e){
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password, code } = this.state

    this.setState({ isLoading: true })
    AuthManager.resetPassword(email, password, code)
      .then(() => {
        this.setState({
          isLoading: false,
          mode: MODE_SIGNIN,
          email: "",
          password: "",
          confirmPassword: "",
        })
        Notify.success("Password reset successfully!")
      })
      .catch(error => {
        Notify.error(error.message)
        this.setState({
          isLoading: false,
          error: error.message
        })
      })

  }

  _handleRequestResetPassword(e){
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password, confirmPassword } = this.state

    this.setState({ isLoading: true })
    AuthManager.requestResetPassword(email)
      .then(() => {
        this.setState({
          isLoading: false,
          mode: MODE_RESET_PASSWORD,
          password: "",
          confirmPassword: "",
        })
      })
      .catch(error => {
        Notify.error(error.message)
        this.setState({
          isLoading: false,
          error: error.message
        })
      })
  }

  _handleLogInClicked(e) {
    e.preventDefault()

    let { email, password } = this.state

    this.setState({ isLoading: true })
    AuthManager.login(email, password)
      .then(() => {
        let url = "/"
        window.location = url
      })
      .catch(error => {
        Notify.error(error.message)
        this.setState({
          isLoading: false,
          error: error?.message
        })
      })
  }

  _renderLogin(){
    let {
      email,
      password,
      confirmPassword,
      isLoading,
      mode,
      code,
      signUpStep,
      showImagePicker,
      pkg
    } = this.state

    return (
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate="novalidate"
        id="kt_sign_in_form"
      >
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">Sign In</h1>
        </div>
        <div className="fv-row mb-10 fv-plugins-icon-container">
          <label className="form-label fs-6 fw-bolder text-dark">Email</label>
          <input
            className="form-control form-control-lg form-control-solid"
            type="text"
            name="email"
            autoComplete="off"
            value={email}
            onChange={e => this.setState({email: e.target.value, error: null})}
            autoFocus
          />
          <div className="fv-plugins-message-container invalid-feedback"></div>
        </div>
        <div className="fv-row mb-10 fv-plugins-icon-container">
          <div className="d-flex flex-stack mb-2">
            <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
            <a
              className="link-primary fs-6 fw-bolder"
              onClick={() => this.setState({email: "", mode: MODE_FORGOT_PASSWORD, error: null})}
            >
              Forgot Password?
            </a>
          </div>
          <input
            className="form-control form-control-lg form-control-solid"
            type="password"
            name="password"
            autoComplete="off"
            value={password}
            onChange={e => this.setState({password: e.target.value, error: null})}
          />
          <div className="fv-plugins-message-container invalid-feedback"></div>
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="btn btn-lg btn-primary w-100 mb-5"
            onClick={e => this._handleLogInClicked(e)}
            disabled={isLoading}
          >
            <span className="indicator-label">Sign In</span>
          </button>
        </div>
        <div></div>
      </form>
    )
  }

  _renderForgotPassword(){
    let {
      email,
      password,
      confirmPassword,
      isLoading,
      mode,
      code,
      signUpStep,
      showImagePicker,
      pkg
    } = this.state

    return (
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate="novalidate"
        id="kt_password_reset_form"
      >
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">Forgot Password ?</h1>
          <div className="text-gray-400 fw-bold fs-4">Enter your email to reset your password.</div>
        </div>
        <div className="fv-row mb-10 fv-plugins-icon-container">
          <label className="form-label fw-bolder text-gray-900 fs-6">Email</label>
          <input
            className="form-control form-control-solid"
            type="email"
            placeholder=""
            name="email"
            autoComplete="off"
            value={email}
            onChange={e => this.setState({email: e.target.value, error: null})}
            autoFocus
          />
          <div className="fv-plugins-message-container invalid-feedback"></div>
        </div>
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="submit"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bolder me-4"
            onClick={e => {
              this._handleRequestResetPassword(e)
            }}
          >
            <span className="indicator-label">Submit</span>
            <span className="indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
          <a
            className="btn btn-lg btn-light-primary fw-bolder"
            onClick={e => {
              e.preventDefault()
              this.setState({email: "", password: "", mode: MODE_SIGNIN, error: null})
            }}
          >
            Cancel
          </a>
        </div>
        <div></div>
      </form>
    )
  }

  _renderResetPassword(){
    let {
      password,
      confirmPassword,
      isLoading,
      code,
    } = this.state

    return (
      <form className="form w-100" noValidate="novalidate" id="kt_sign_in_form">
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">Reset Password</h1>
          <div className="text-gray-400 fw-bold fs-4">
            A verification code was sent to your email, please enter it below along with your new password
          </div>
        </div>

        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
          <input
            className="form-control form-control-lg form-control-solid"
            type="password"
            name="password"
            autoComplete="off"
            value={password}
            onChange={e => this.setState({password: e.target.value, error: null})}
          />
        </div>

        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-dark fs-6 mb-0">Confirm Password</label>
          <input
            className="form-control form-control-lg form-control-solid"
            type="password"
            name="password"
            autoComplete="off"
            value={confirmPassword}
            onChange={e => this.setState({confirmPassword: e.target.value, error: null})}
          />
        </div>

        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">Code</label>
          <input
            className="form-control form-control-lg form-control-solid"
            type="text"
            name="code"
            autoComplete="off"
            value={code}
            onChange={e => this.setState({code: e.target.value, error: null})}
          />
        </div>

        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="submit"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bolder me-4"
            onClick={e => this._handleResetPassword(e)}
            disabled={isLoading}
          >
            <span className="indicator-label">Submit</span>
            <span className="indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
          <a
            className="btn btn-lg btn-light-primary fw-bolder"
            onClick={e => {
              e.preventDefault()
              this.setState({email: "", password: "", mode: MODE_SIGNIN, error: null})
            }}
          >
            Cancel
          </a>
        </div>

      </form>
    )
  }

  render() {
    let {
      data,
      mode,
      activeTabIndex
    } = this.state

    return (
      <>
        <div className="d-flex flex-column flex-root">

          <div className="d-flex flex-column flex-lg-row flex-column-fluid">

            <div
              className="d-flex flex-column flex-lg-row-auto w-xl-600px position-xl-relative bgi-no-repeat bgi-size-cover"
              style={{backgroundImage: "url('https://images.pexels.com/photos/3057960/pexels-photo-3057960.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')"}}
            >

              <div
                className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px h-100"
                style={{backgroundColor: 'rgb(8, 47, 73, 0.9)'}}
              >

                <div className="d-flex flex-row-fluid flex-column flex-center p-10 pt-lg-20" style={{justifyContent: 'center'}}>

                  <Link to={"/login"} className="py-9 mb-5 text-center">
                    <img alt="Logo" src={require("../assets/media/logos/logo-dark.png")} className="logo" style={{ width: "60%" }}/>
                  </Link>

                </div>

              </div>

            </div>


            <div className="d-flex flex-column flex-lg-row-fluid py-10">

              <div className="d-flex flex-center flex-column flex-column-fluid" data-select2-id="select2-data-15-7d58">

                <div className="w-lg-500px p-10 p-lg-15 mx-auto">
                  {mode === MODE_SIGNIN && this._renderLogin()}
                  {mode === MODE_FORGOT_PASSWORD && this._renderForgotPassword()}
                  {mode === MODE_RESET_PASSWORD && this._renderResetPassword()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
