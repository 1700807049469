import React from "react"
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import ReactTable from "react-table"
import "react-table/react-table.css"
import moment from "moment";

import General from "../../../utils/General";
import FetchHelper from "../../../utils/FetchHelper";
import Card from "../structure/Card";
import AsyncSelect from "../common/AsyncSelect";
import Select from "../common/Select";
import DateRange from "../common/DateRange";
import CountrySelect from "../common/CountrySelect";

const OBJECTS_FILTERS = {
  name: {
    api: "objects",
    display: "Status"
  },
  values: [
    {
      label: "All",
      value: "all"
    },
    {
      label: "Active",
      value: "active"
    },
    {
      label: "Hidden",
      value: "deleted"
    }
  ]
}

export default class BaseTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      loading: true,
      data: [],
      dateFrom: null,
      dateTo: null,
      pagesNo: 0,
      searchTerm: "",
      csvData: [],
      filterValue: {objects: this.props.objects},
      searchFilter: this.props.searchFilter,
      filters: this.props.filters ? [...this.props.filters, OBJECTS_FILTERS] : [OBJECTS_FILTERS],
    }

    this.reactTable = React.createRef();
  }

  refresh() {
    let current = this.reactTable.current;
    if (current) {
      current.state.page = 0
      this.setState({
        loading: false
      }, () => this._fetchData(current.state, current))
    }
  }

  _handleSearch = General.debounce(() => {
    let current = this.reactTable.current
    this.refresh()
  }, 500)

  _handleFilterChange(event){
    let { filterValue } = this.state

    filterValue[event.target.name] = event.target.value
    this.setState({filterValue}, () => this._handleSearch())
  }

  _getUrl(endpoint, state){

    let params = {
      ...this.props.params,
      page_size: state.pageSize,
      page: state.page + 1,
      pagination_type: "page",
      search_term: this.state.searchTerm
    }

    let sorted = state.sorted[0]
    if(sorted){
      let orderBy = sorted.id
      orderBy = orderBy.replace(/\./g, "__")
      if(sorted.desc){
        orderBy = `-${orderBy}`
      }
      params["order_by"] = orderBy
    }

    if(this.state.filterValue){
      Object.entries(this.state.filterValue).map(filter => {
        params[filter[0]] = filter[1].id ? filter[1].id : filter[1].user ? filter[1].user.id : filter[1]
      })
    }

    if(this.props.dateRange){
      params["date_from"] = this.state.dateFrom?.format('YYYY-MM-DD')
      params["date_to"] = this.state.dateTo?.format('YYYY-MM-DD')
    }

    return this._addParams(endpoint, params)
  }

  _fetchData(state, instance) {
    this.setState({ loading: true });
    let url = this._getUrl(this.props.endpoint, state)

    if(this.props.endpoint === ""){
      this.setState({
        data: this.props.mockData,
        pagesNo: 1,
        loading: false
      });
      return
    }
    FetchHelper.get(url)
      .then(response => {
        this.setState({
          data: response.results,
          pagesNo: Math.ceil(response.count / state.pageSize),
          totalRecords: response.count,
          loading: false
        });
      })
      .catch(error => {

      })
  }

  _addParams(url, params){
    if(Object.keys(params).length == 0){
      return url
    }

    // TODO: switch to an actual url helper here to avoid bugs/edge cases
    if(url.indexOf("?") == -1){
      url += "?"
    }
    else if(!url.endsWith("&")){
      url += "&"
    }

    Object.keys(params).forEach(function(key) {
      url += key+"="+params[key]+"&";
    });

    // remove last '&'
    url = url.slice(0, -1);
    return url
  }

  _renderHeaderLeftContent(){

  }

  _getFilterOption(value){
    if (!value || typeof value !== 'object') return ''
    let label = ""
    if(value.tax_no){
      label = `${value.type === 'local' ? value.country_code : ''} ${value.type === 'local' ? General.toTitleCase(value.type) : value.type.toUpperCase()}`
    }else if(value.first_name){
      label = `${value.first_name} ${value.last_name}`
    }else if(value.type === "pos_terminal"){
      label = `POS Terminal`
    }else if(value.user){
      label = `${value.user.first_name} ${value.user.last_name}`
    }else if(value.name){
      label = value.name
    }
    return {
      value: value.id,
      label: label,
      data: value
    }
  }

  _getFilterOptions(values){
    return values.map(value => {
      let label = ""
      if(value.tax_no){
        label = `${value.type === 'local' ? value.country_code : ''} ${value.type === 'local' ? General.toTitleCase(value.type) : value.type.toUpperCase()}`
      }else if(value.first_name){
        label = `${value.first_name} ${value.last_name}`
      }else if(value.type === "pos_terminal"){
        label = `POS Terminal`
      }else if(value.user){
        label = `${value.user.first_name} ${value.user.last_name}`
      }else if(value.name){
        label = value.name
      }
      return (
        {
          value: value.id,
          label: label,
          data: value
        }
      )
    })
  }

  _renderFilter(){
    let {
      filterValue,
      showDropdown
    } = this.state

    if(!this.props.showFilter) return null
    return (
      <>
        { this.props.showSearch &&
          <div className="col ">
            <div className="w-100 position-relative">
              <span
                className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                        transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                  <path
                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                    fill="black"></path>
                </svg>
              </span>
              <input
                type="text"
                className="form-control form-control-solid px-15"
                name="search"
                onChange={e => {
                  this.setState({
                    searchTerm: e.target.value
                  }, () => this._handleSearch())
                }}
                placeholder="Search..."
                data-kt-search-element="input"
              />
            </div>
          </div>
        }

        {
          this.props.dateRange &&
          <div className=" ms-5">
            <DateRange
              dateFrom={this.state.dateFrom}
              dateTo={this.state.dateTo}
              onFromDateUpdated={(dateFrom) => {
                this.setState({
                  dateFrom,
                }, () => this._handleSearch())
              }}
              onToDateUpdated={(dateTo) => {
                this.setState({
                  dateTo,
                }, () => this._handleSearch())
              }}
            />
          </div>
        }

        {
          this.props.filters &&
          <div className=" basetable-filter ms-5" >
            <Dropdown className="d-inline " autoClose="outside" show={showDropdown} onToggle={() => this.setState({ showDropdown: !showDropdown})}>
              <Dropdown.Toggle id="dropdown-autoclose-outside" variant={"light-primary"} onClick={() => this.setState({ showDropdown: true})}>
                <span className="svg-icon svg-icon-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                      fill="black"
                    />
                  </svg>
                </span>
                Filter
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{width: 250, padding: 20}}
                align={{lg: 'end', md: 'end', sm: 'end'}}
              >
                {
                  this.props.filters.map(filter => {
                    return (
                      <>
                        <div className="mb-10">
                          <label className="form-label fs-6 fw-bold">{filter.name.label}:</label>
                          {
                            filter.endpoint &&
                            <Dropdown.Item>
                              <AsyncSelect
                                isClearable={true}
                                placeholder={filter.name.label}
                                endpoint={filter.endpoint.url}
                                orderBy={filter.endpoint.orderBy}
                                value={this._getFilterOption(filterValue[filter.name.api])}
                                filter={filter.endpoint.filters}
                                className={'filter-solid custom-async-select__container'}
                                classNamePrefix={'custom-async-select'}
                                getOptions={options => this._getFilterOptions(options)}
                                onSelected={option => {
                                  if(option) {
                                    filterValue[filter.name.api] = option
                                  }else{
                                    delete filterValue[filter.name.api]
                                  }
                                  this.setState({filterValue})
                                }}
                              />
                            </Dropdown.Item>
                          }
                          {
                            filter.values &&
                            <Dropdown.Item>
                              <Select
                                isClearable={true}
                                value={filterValue[filter.name.api]}
                                options={filter.values}
                                placeholder={filter.name.label}
                                getOptionLabel={role => role.label}
                                getOptionValue={role => role.value}
                                className="filter-solid custom-async-select__container"
                                classNamePrefix="custom-async-select"
                                onSelected={option => {
                                  if(option) {
                                    filterValue[filter.name.api] = option.value
                                  }else{
                                    delete filterValue[filter.name.api]
                                  }
                                  this.setState({filterValue})
                                }}
                              />
                            </Dropdown.Item>
                          }
                          {
                            filter.type === "country" &&
                            <CountrySelect
                              isClearable={true}
                              className="filter-solid custom-async-select__container"
                              classNamePrefix="custom-async-select"
                              value={filterValue[filter.name.api]}
                              onSelected={option => {
                                if(option) {
                                  filterValue[filter.name.api] = option.value
                                }else{
                                  delete filterValue[filter.name.api]
                                }
                                this.setState({filterValue})
                              }}
                            />
                          }
                        </div>
                      </>
                    )
                  })
                }
                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary fw-bold px-6"
                    data-kt-menu-dismiss="true"
                    data-kt-docs-table-filter="filter"
                    onClick={() => {
                      this._handleSearch()
                      this.setState({ showDropdown: false })
                    }}
                  >
                    Apply
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        }


      </>
    )
  }

  render() {
    const {data, pagesNo, loading, totalRecords} = this.state;

    return (
      <>
        <Card
          title={this.props.title}
          subtitle={this.props.subtitle || `${totalRecords || 0} Total`}
          showHeader={this.props.showHeader}
          showToolbar={this.props.showToolbar}
          renderToolbar={this.props.renderToolbar}
          renderFilter={() => this._renderFilter()}
        >
          <div className="table-responsive">
            <ReactTable
              ref={this.reactTable}
              manual
              data={data}
              pages={pagesNo}
              totalRecords={totalRecords}
              loading={loading}
              onFetchData={this._fetchData.bind(this)}
              columns={this.props.columns}
              getTdProps={this.props.getTdProps}
              getTrProps={this.props.getTrProps}
              getTheadProps={this.props.getTheadProps}
              getTheadThProps={this.props.getTheadThProps}
              showPagination={this.props.showPagination}
              showPaginationTop={this.props.showPaginationTop}
              showPaginationBottom={this.props.showPaginationBottom}
              defaultSorted={this.props.defaultSorted}
              SubComponent={this.props.SubComponent}
              NoDataComponent={() => {
                return (
                  <div className="rt-noData">
                    <div className="text-center m-20 py-10">
                      <span className="fw-bolder fs-3 text-muted">{ this.props.noDataMessage}</span>
                    </div>
                  </div>
                )
              }}
            />
          </div>
        </Card>
      </>
    )
  }

}

BaseTable.defaultProps = {
  params: {},
  objects: 'all',
  label: "Search:",
  searchPlaceholder: "Search...",
  showToolbar: true,
  showHeader: true,
  showFilter: true,
  icon: null,
  filters: null,
  searchFilter: null,
  searchFilterName: "name",
  noDataMessage: "No documents found",
  showSearch: true,
  showPagination: true,
  showPaginationTop: false,
  showPaginationBottom: true,
  exportButtonsEnabled: true,
  getTdProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        paddingLeft: 10
      }
    }
  },
  getTrProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        paddingTop: 10,
        paddingBottom: 10
      }
    }
  },
  getTheadProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        boxShadow: '0 1px 1px 0 rgba(0,0,0,0.1)',
        paddingTop: 10,
        paddingBottom: 10,
        textAlign: 'left'
      }
    }
  },
  getTheadThProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        textAlign: 'left',
        paddingLeft: 10
      }
    }
  },
  SubComponent: null,
  renderHeaderRightContent: () => null,
  renderToolbar: () => null,
  renderFilter: () => null
}

class Loading extends React.Component {
  render () {
    return (
      this.props.loading
        ? <div className='-loading -active'>
          <div className='-loading-inner'>
          </div>
        </div>
        : null
    )
  }
}
