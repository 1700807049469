import React from "react"
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";

import UserModal from "../modals/User";
import ConfirmationModal from "../modals/Confirmation";

import BaseTable from "./BaseTable"
import EditCell from "./cells/EditCell";
import DeleteCell from "./cells/DeleteCell";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import Permissions from "../../../utils/Permissions";
import General from "../../../utils/General";

const STATUS_FILTER = {
  name: {
    api: 'status',
    display: 'Status',
  },
  endpoint: {
    url:`${window.Api.Statuses}`,
    filters: '',
    orderBy: 'name',
    accessor: ''
  }
}

const SOURCE_FILTER = {
  name: {
    api: 'source',
    display: 'Source',
  },
  endpoint: {
    url:`${window.Api.Sources}`,
    filters: '',
    orderBy: 'name',
    accessor: ''
  }
}

const FILTERS = [STATUS_FILTER, SOURCE_FILTER]

class TeamMembers extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      type: props.type
    }

    this.table = React.createRef()
  }

  _getColumns(){
    let columns = [
      {
        Header: 'User',
        id: 'user.first_name',
        width: 200,
        Cell: rowInfo => {
          let teamMember = rowInfo.original
          let user = teamMember.user
          let title = user.first_name + " " + user.last_name
          let subtitle = user.email

          return (
            <div class="d-flex align-items-center">
              <div class="d-flex justify-content-start flex-column">
                <a class="text-dark fw-bolder text-hover-primary fs-6">{title}</a>
                <span class="text-muted fw-bold text-muted d-block fs-7">{subtitle}</span>
              </div>
            </div>
          )
        }
      },

      {
        Header: 'Role',
        id: 'role.name',
        accessor: teamMember => teamMember.role.name,
      },
      {
        Header: 'Permissions',
        id: 'permissions',
        width: 700,
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let teamMember = rowInfo.original

          let permissions = []
          Permissions.OPTIONS.map(permission => {
            let permissionString = teamMember.role.permissions[permission.key].join(',')
            let selectedPermission = permission.options.find((option) => {
              return option.value === permissionString
            })
            if(selectedPermission){
              permissions.push({
                name: permission.label,
                color: permission.color,
                key: selectedPermission.label
              })
            }
          })
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start">
                {
                  permissions.map(permission => (
                    <>
                      <Tooltip title={permission.key} placement={"top"} arrow>
                        <span className={`badge ${permission.color} badge-sm fs-base ms-3`}>
                          {permission.name}
                        </span>
                      </Tooltip>
                    </>
                  ))
                }
              </div>
            </div>
          )
        },
      },
      {
        Header: 'Date Added',
        id: 'created_at',
        accessor: teamMember => moment(teamMember.created_at).format('DD MMM YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let teamMember = rowInfo.original
          let isCurrentUser = teamMember.user.id === AuthManager.currentUser.user.id

          let isAdmin = AuthManager.currentUser.user.role === "admin"

          let canEdit = !isCurrentUser && isAdmin
          let canDelete = !isCurrentUser && isAdmin

          return (
            <div className={"my-auto float-end"}>
              <>
                {
                  canEdit &&
                  <EditCell
                    tooltip={"Edit"}
                    onEditClicked={() => {
                      this.setState({
                        showTeamMemberModal: true,
                        teamMember,
                      })
                    }}
                  />
                }
                {
                  canDelete &&
                  <DeleteCell
                    tooltip={"Delete"}
                    iconClass={"la-trash"}
                    onDeleteClicked={() => {
                      this.setState({
                        showConfirmationModal: true,
                        teamMember,
                      })
                    }}
                  />
                }
              </>
            </div>
          )
        }
      }
    ]

    return columns
  }

  _deleteTeamMember(){
    let {
      type,
      teamMember,
    } = this.state

    this.setState({loading: true})

    Backend.deleteTeamMember(teamMember, type).then(() => {
      Notify.success('Member has been deleted successfully')
      this.setState({
        teamMember: null,
        loading: false,
        showConfirmationModal: false,
      })
      this.table.current.refresh()
    }).catch(e => {
      Notify.error(e.message)
      this.setState({
        teamMember: null,
        loading: false,
        showConfirmationModal: false,
      })
    })
  }

  _renderTeamMemberModal(){
    let { type, teamMember, showTeamMemberModal } = this.state

    if(!showTeamMemberModal) return null

    return (
      <UserModal
        show={showTeamMemberModal}
        type={type}
        teamMember={General.clone(teamMember)}
        onHide={() => this.setState({ showTeamMemberModal: false, teamMember: null })}
        onAdded={(member) => {
          this.table.current.refresh()
          this.setState({ showTeamMemberModal: false, teamMember: null })
        }}
      />
    )
  }

  _renderConfirmationModal(){
    let { showConfirmationModal } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={"Are you sure?"}
        body={" This action can't be undone."}
        confirmTitleText={"Delete"}
        deleteTheme={"danger"}
        loading={this.state.loading}
        onConfirm={() => this._deleteTeamMember()}
        onHide={() => this.setState({ showConfirmationModal: false, teamMember: null })}
      />
    )
  }

  render() {
    let {
      type
    } = this.state

    const columns = this._getColumns()

    let endpoint = window.Api.Agent
    let noDataMessage = "No Agent Found"
    let title = "Agent"
    if(type === "admin"){
      endpoint = window.Api.Admins
      noDataMessage = "No Admin Found"
      title = "Admin"
      columns.splice(1, 2)
    }



    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${endpoint}`}
          noDataMessage={noDataMessage}
          title={title}
          columns={columns}
          objects={'active'}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => (
            <>
              <button
                type="button"
                className="btn btn-primary ms-5"
                onClick={() => this.setState({showTeamMemberModal: true})}
              >
                + Add User
              </button>
            </>
          )}
        />
        {this._renderTeamMemberModal()}
        {this._renderConfirmationModal()}
      </>
    )

  }

}

export default withRouter(TeamMembers);
