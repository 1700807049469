import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import BaseTable from "./BaseTable"
import View from "./cells/ViewCell";
import Transaction from "./cells/TransactionCell";
import Message from "./cells/MessageCell";

const STATUS_FILTER = {
  name: {
    api: 'status',
    label: 'Onboarding Step',
  },
  values: [
    {
      label: "Detail",
      value: "detail"
    },
    {
      label: "Tax",
      value: "tax"
    },
    {
      label: "Integration",
      value: "integration"
    },
    {
      label: "Complete",
      value: "complete"
    }
  ]
}

const BANK_CONNECTED_FILTER = {
  name: {
    api: 'bank_connected',
    label: 'Bank Account',
  },
  values: [
    {
      label: "Connected",
      value: "true"
    },
    {
      label: "Not Connected",
      value: "false"
    }
  ]
}

const SHOPIFY_CONNECTED_FILTER = {
  name: {
    api: 'integration_connected',
    label: 'Shopify Connected',
  },
  values: [
    {
      label: "Connected",
      value: "true"
    },
    {
      label: "Not Connected",
      value: "false"
    }
  ]
}

const FILTERS = [STATUS_FILTER, BANK_CONNECTED_FILTER, SHOPIFY_CONNECTED_FILTER]

class Companies extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }

    this.table = React.createRef()
  }

  _getColumns(){
    let columns = [
      {
        Header: 'Date',
        id: 'created_at',
        Cell: rowInfo => {
          let company = rowInfo.original

          return (
            <div className="d-flex align-items-center">
              <a className="text-dark text-hover-primary fs-6">
                {moment(company.created_at).format('DD MMM YYYY')}
              </a>
            </div>
          )
        }
      },
      {
        Header: 'Company',
        id: 'name',
        Cell: rowInfo => {
          let company = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a className="text-dark fw-bolder text-hover-primary fs-6">{company.name}</a>
                <span class="text-muted fw-bold text-muted d-block fs-7">{company.created_by.user.email}</span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Onboarding',
        id: 'info.status',
        Cell: rowInfo => {
          let company = rowInfo.original
          let onboardingStatus = company.info.status
          let className = 'badge-success'
          let status = "Complete"

          if(onboardingStatus === "detail"){
            className = 'badge-warning'
            status = "Tax Details"
          }

          if(onboardingStatus === "tax"){
            className = 'badge-dark'
            status = "Company Info"
          }

          if(onboardingStatus === "integration"){
            className = "badge-danger"
            status = "Shopify"
          }

          return (
            <>
              <span class={`badge ${className} badge-sm fs-base m-lg-auto`}>
								{ status }
							</span>
            </>
          )
        }
      },
      {
        Header: 'Shopify',
        id: 'active_integrations_no',
        Cell: rowInfo => {
          let company = rowInfo.original
          let className = company.active_integrations_no > 0 ? 'badge-light-success' : 'badge-light-dark'
          let title = company.active_integrations_no > 0 ? 'Connected' : 'Pending'
          return (
            <span className={`badge ${className} badge-sm fs-base m-lg-auto`}>
              {title}
            </span>
          )
        }
      },
      {
        Header: 'Bank Acc',
        id: 'bank_account_connected',
        Cell: rowInfo => {
          let company = rowInfo.original
          let className = 'badge-light-success'
          let text = 'Connected'
          if(!company.bank_account_connected){
            className = 'badge-light-warning'
            text = 'Not Connected'
          }
          return (
            <span class={`badge ${className} badge-sm fs-base m-lg-auto`}>
							{text}
						</span>
          )
        }
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let company = rowInfo.original
          return (
            <div className={"my-auto float-end"}>
              <View
                tooltip={"View"}
                onViewClicked={() => {
                  this.props.history.push(`/company/${company.id}`)
                }}
              />
              <Transaction
                tooltip={"Transactions"}
                onTransactionClicked={() => {
                  this.props.history.push(`/company/${company.id}#transactions`)
                }}
              />
              <Message
                tooltip={"Messages"}
                onMessageClicked={() => {
                  this.props.history.push(`/company/${company.id}#messages`)
                }}
              />
            </div>
          )
        }
      }
    ]

    return columns
  }

  render() {
    let {

    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Companies}`}
          noDataMessage={"No companies found"}
          title={this.props.title}
          columns={columns}
          filters={FILTERS}
          objects={'active'}
          showSearch={this.props.showSearch}
          showPagination={this.props.showPagination}
          dateRange={true}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => (
            <>
              {
                !this.props.showSearch &&
                <button
                  type="button"
                  className="btn btn-primary ms-5"
                  onClick={() => {
                    this.props.history.push(`/companies`)
                  }}
                >
                  + View All
                </button>
              }
            </>
          )}
        />
      </>
    )

  }

}

Companies.defaultProps = {
  title: "Companies",
  showSearch: true,
  showPagination: true
}

export default withRouter(Companies);
