import AuthManager from './AuthManager'
import General from "./General";

// returns an authenticated fetch requests if possible
// these method are just shortcuts for including headers into
// fetch requests
const PAGE_LIMIT = 20
export default class FetchHelper {
  static get(endpoint, validateTokens = true) {
    let data = {}
    let statusCode = null

    return FetchHelper._handleValidateTokens(validateTokens).then(() => {
      data['headers'] = AuthManager.getHeaders('application/json', validateTokens)
      return fetch(endpoint, data)
        .then(response => {
          statusCode = response.status
          return response.json()
        })
        .then(responseJson => {
          let status = { code: statusCode, success: responseJson.status }
          if (this._hasError(status)) {
            throw FetchHelper._getError(responseJson)
            return
          }

          return responseJson
        })
    })
  }

  static getPaginated(
    endpoint,
    page,
    validateTokens = true,
    pageLimit = PAGE_LIMIT
  ) {
    if (endpoint.includes('?')) {
      endpoint += '&'
    } else {
      endpoint += '?'
    }
    return FetchHelper._handleValidateTokens(validateTokens).then(() => {
      return FetchHelper.get(endpoint + 'page=' + page).then(responseJson => {
        return responseJson
      })
    })
  }

  static post(endpoint, data, isMultiPart = false, validateTokens = true) {
    let statusCode = null
    return FetchHelper._handleValidateTokens(validateTokens).then(() => {
      return fetch(endpoint, {
        method: 'POST',
        headers: isMultiPart
          ? AuthManager.getHeaders('multipart/form-data', validateTokens)
          : AuthManager.getHeaders('application/json', validateTokens),
        body: isMultiPart ? data : JSON.stringify(data)
      })
        .then(response => {
          statusCode = response.status
          if (statusCode == 204) {
            return response
          }
          return response.json()
        })
        .then(responseJson => {
          let status = { code: statusCode, success: responseJson.status }
          if (this._hasError(status)) {
            throw FetchHelper._getError(responseJson)
            return
          }
          return responseJson
        })
    })
  }

  static _handleValidateTokens(validateTokens) {
    if (validateTokens) {
      return AuthManager.validateTokens()
    }

    return new Promise((resolve, reject) => {
      resolve()
    })
  }

  static patch(endpoint, data, stringify = true, validateTokens = true) {

    let statusCode = null
    return FetchHelper._handleValidateTokens(validateTokens).then(() => {
      var headers = AuthManager.getHeaders('application/json', validateTokens)

      if (stringify) {
        data = JSON.stringify(data)
      } else {
        headers = AuthManager.getHeaders('multipart/form-data', validateTokens)
      }

      return fetch(endpoint, {
        method: 'PATCH',
        headers: headers,
        body: data
      })
        .then(response => {
          statusCode = response.status
          if (statusCode == 204) {
            return response
          }
          return response.json()
        })
        .then(responseJson => {
          let status = { code: statusCode, success: responseJson.status }
          if (this._hasError(status)) {
            throw FetchHelper._getError(responseJson)
          }
          return responseJson
        })
    })
  }

  static put(endpoint, data, stringify = true, validateTokens = true, parseResponse=true) {
    var headers = AuthManager.getHeaders();

    if (stringify) {
      data = JSON.stringify(data);
    } else {
      headers = AuthManager.getHeaders("multipart/form-data", validateTokens);
    }

    let statusCode = null;
    return FetchHelper._handleValidateTokens(validateTokens).then(() => {
      return fetch(endpoint, {
        // this is needed by server side for all put requests
        method: "PUT",
        headers: headers,
        body: data,
      })
        .then((response) => {
          statusCode = response.status;
          if(!parseResponse){
            return response
          }
          return response.json();
        })
        .then((responseJson) => {
          let status = { code: statusCode, success: responseJson.status };
          if (this._hasError(status)) {
            throw FetchHelper._getError(responseJson);
          }

          return responseJson;
        });
    });
  }

  static delete(endpoint, data, validateTokens = true) {
    let statusCode = null
    return FetchHelper._handleValidateTokens(validateTokens).then(() => {
      return fetch(endpoint, {
        method: 'DELETE',
        headers: AuthManager.getHeaders('application/json', validateTokens),
        body: JSON.stringify(data)
      })
        .then(response => {
          statusCode = response.status
          if (statusCode == 204) {
            return response
          }
          return response.json()
        })
        .then(responseJson => {
          let status = { code: statusCode, success: responseJson.status }
          if (this._hasError(status)) {
            throw FetchHelper._getError(responseJson)
            return
          }
          return responseJson
        })
    })
  }

  static download(endpoint, filename, validateTokens = true) {
    let statusCode = null
    return FetchHelper._handleValidateTokens(validateTokens).then(() => {
      let data = {}
      data['headers'] = AuthManager.getHeaders('application/json', validateTokens)
      return fetch(endpoint, data)
        .then(response => {
          statusCode = response.status
          return response.blob()
        })
        .then(responseBlob => {
          let status = { code: statusCode, success: responseBlob.status }
          if (this._hasError(status)) {
            throw FetchHelper._getError(responseBlob)
            return
          }

          return responseBlob
        })
        .then((data) => {
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(data);
          link.download = filename;
          link.click();
        })
    })
  }

  static _hasError({ code, success }) {
    return code < 200 || code > 299 || success == false
  }

  static _getError(responseJson) {
    let error = null
    if (responseJson.message) {
      error = responseJson.message
    } else if (responseJson.non_field_errors) {
      if(responseJson.non_field_errors instanceof Array){
        error = responseJson.non_field_errors[0]
      }
      else{
        error = responseJson.non_field_errors
      }
    } else {
      error = responseJson
    }

    if (error.constructor === Object) {
      error = FetchHelper._parseError(error[Object.keys(error)[0]], Object.keys(error)[0])
    }

    let message = 'An unexpected error occured'

    if (error) {
      message = error
    }

    return { error: error, message: message }
  }

  static _parseError(error, key){
    if (error instanceof Array) {
      if(error[0] instanceof Object){
        return FetchHelper._parseError(error[0])
      }
      return `${General.snakeCaseToTitleCase(key)}: ${error[0]}`
    } else if (typeof error === 'string') {
      return `${General.snakeCaseToTitleCase(key)}: ${error}`
    } else if(error instanceof Object){
      return FetchHelper._parseError(error[Object.keys(error)[0]], Object.keys(error)[0])
    }
  }

  static _hasMore(results, pageLimit) {
    if (results.current_page) {
      return results.current_page < results.last_page
    }
    return results.data.length >= pageLimit
  }
}
