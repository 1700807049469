import FetchHelper from './FetchHelper'
import AuthManager from './AuthManager'
import moment from "moment";

export default class Backend {

  static updateAccount(data){
    let endpoint = AuthManager.currentUser.user.role === "admin" ? window.Api.Admins : window.Api.Agent
    data = JSON.parse(JSON.stringify(data))
    delete data.user.email
    return FetchHelper.patch(`${endpoint}/${AuthManager.currentUser.user.id}`, data)
  }

  static uploadImage(image, metaData){
    let file = null

    let meta = {
      name: image.name.toLowerCase(),
      height: Math.round(metaData.height),
      width: Math.round(metaData.width),
    }

    return Backend.getPreSignedUploadUrl(meta).then(response => {
      file = response.image;
      return FetchHelper.put(response.pre_signed_url, image, false, false, false).then(() => Backend.markImageUploaded(file.id, file.token))
    })
  }

  static getPreSignedUploadUrl(data){
    return FetchHelper.post(window.Api.Images, data);
  }

  static markImageUploaded(id, token) {
    let data = {uploaded: true, token}
    return FetchHelper.patch(`${window.Api.Images}/${id}`, data);
  }

  static markFileUploaded(id, token) {
    return FetchHelper.patch(`${window.Api.FilesV2}/${id}`, { token });
  }

  static uploadFile(file, endpoint, params){
    let data = new FormData()

    data.append("file", file, file.name)
    for(var key in params){
      data.append(key, params[key])
    }

    return FetchHelper.post(endpoint, data, true)
  }

  static getCompany(id){
    return FetchHelper.get(`${window.Api.Companies}/${id}`)
  }

  static updateCompany(companyData) {
    let data = JSON.parse(JSON.stringify(companyData));
    return FetchHelper.patch(`${window.Api.Companies}/${companyData.id}`, data)
  }

  static getFiling(id){
    return FetchHelper.get(`${window.Api.Filings}/${id}`)
  }

  static getCurrentFilings(companyId){
    return FetchHelper.get(`${window.Api.Filings}/latest?company_id=${companyId}`)
  }

  static getFilings(companyId, taxNumberId, pageSize){
    return FetchHelper.get(`${window.Api.Filings}?company_id=${companyId}&tax_number_id=${taxNumberId}&page_size=${pageSize}&order_by=created_at&pagination_type=page`)
  }

  static addTeamMember(teamMember, type){
    let data = JSON.parse(JSON.stringify(teamMember));
    data.role = data.role ? data.role.id : data.role
    if(data.companies){
      data.companies = data.companies.map(company => company.id)
    }
    let endpoint = type === "admin" ? window.Api.Admins : window.Api.Agent
    return FetchHelper.post(endpoint, data);
  }

  static updateTeamMember(teamMember, type){
    let data = JSON.parse(JSON.stringify(teamMember));
    data.role = data.role ? data.role.id : data.role
    if(data.companies){
      data.companies = data.companies.map(company => company.id)
    }
    let endpoint = type === "admin" ? window.Api.Admins : window.Api.Agent
    return FetchHelper.patch(
      `${endpoint}/${teamMember.user.id}`,
      data
    );
  }

  static submitFiling(filing){
    let data = JSON.parse(JSON.stringify(filing));
    return FetchHelper.post(`${window.Api.Revisions}`, data)
  }

  static updateFiling(filing){
    let data = JSON.parse(JSON.stringify(filing));
    return FetchHelper.patch(`${window.Api.Filings}/${filing.id}`, data)
  }

  static deleteFiling(filing){
    return FetchHelper.delete(`${window.Api.Filings}/${filing.id}`)
  }

  static deleteTeamMember(teamMember, type) {
    let endpoint = type === "admin" ? window.Api.Admins : window.Api.Agent
    return FetchHelper.delete(`${endpoint}/${teamMember.user.id}`);
  }

  static getIntegrations(companyId, role){
    return FetchHelper.get(`${window.Api.Integrations}?paginated=false&company_id=${companyId}&role=${role}`)
  }

  static getIntegration(integration){
    return FetchHelper.get(`${window.Api.Integrations}/${integration.id}`)
  }

  static getProblematicProducts(integrationId, companyId){
    return FetchHelper.get(`${window.Api.Products}?integration_id=${integrationId}&company_id=${companyId}&problematic=true`)
  }

  static createRevision(revision){
    let data = JSON.parse(JSON.stringify(revision));
    return FetchHelper.post(`${window.Api.Revisions}`, data)
  }

  static updateRevision(revision){
    let data = JSON.parse(JSON.stringify(revision));
    return FetchHelper.patch(`${window.Api.Revisions}/${revision.id}`, data)
  }

  static deleteRevision(revisionId){
    return FetchHelper.delete(`${window.Api.Revisions}/${revisionId}`)
  }

  static createAdjustment(adjustment){
    let data = JSON.parse(JSON.stringify(adjustment));
    return FetchHelper.post(`${window.Api.Adjustments}`, data)
  }

  static deleteAdjustment(adjustment){
    return FetchHelper.delete(`${window.Api.Adjustments}/${adjustment.id}`)
  }

  static saveRecordProgress(record, document){
    let data = JSON.parse(JSON.stringify(record));
    return FetchHelper.patch(`${window.Api.Documents}/${document.id}`, data)
  }

  static addEntity(entity){
    let data = JSON.parse(JSON.stringify(entity));
    return FetchHelper.post(`${window.Api.Entities}`, data)
  }

  static updateEntity(entity){
    let data = JSON.parse(JSON.stringify(entity));
    return FetchHelper.patch(`${window.Api.Entities}/${entity.id}`, data)
  }

  static addRole(role) {
    return FetchHelper.post(window.Api.Roles, role)
  }

  static deleteRole(role) {
    return FetchHelper.delete(`${window.Api.Roles}/${role.id}`)
  }

  static updateRole(role) {
    return FetchHelper.patch(`${window.Api.Roles}/${role.id}`, role)
  }

  static startConversation(message){
    let data = JSON.parse(JSON.stringify(message));
    if(data.subject === ""){
      delete data.subject
    }
    return FetchHelper.post(window.Api.Conversations, data);
  }

  static getConversation(id){
    return FetchHelper.get(`${window.Api.Conversations}/${id}`)
  }

  static sendMessage(data) {
    return FetchHelper.post(`${window.Api.Messages}`, data)
  }

  static getDocument(documentId){
    return FetchHelper.get(`${window.Api.Documents}/${documentId}`)
  }

  static deleteDocument(documentId) {
    return FetchHelper.delete(`${window.Api.Documents}/${documentId}`)
  }

  static getTransaction(transactionId){
    return FetchHelper.get(`${window.Api.Records}/${transactionId}`)
  }

  static addRule(rule) {
    return FetchHelper.post(window.Api.Rules, rule)
  }

  static deleteRule(rule) {
    return FetchHelper.delete(`${window.Api.Rules}/${rule.id}`)
  }

  static updateRule(rule) {
    return FetchHelper.patch(`${window.Api.Rules}/${rule.id}`, rule)
  }

  static addTransaction(transaction) {
    let data = JSON.parse(JSON.stringify(transaction));
    data.currency = data.currency.id
    data.entity = data.entity.id
    return FetchHelper.post(window.Api.Records, data)
  }

  static updateConversation(conversation){
    return FetchHelper.patch(`${window.Api.Conversations}/${conversation.id}`, conversation)
  }

  static addTaxNumber(tax) {
    let data = JSON.parse(JSON.stringify(tax));
    return FetchHelper.post(window.Api.TaxNumbers, data)
  }

  static deleteTaxNumber(tax) {
    return FetchHelper.delete(`${window.Api.TaxNumbers}/${tax.id}`)
  }

  static updateTaxNumber(tax) {
    let data = JSON.parse(JSON.stringify(tax));
    return FetchHelper.patch(`${window.Api.TaxNumbers}/${tax.id}`, data)
  }

  static addFilingSchedule(filingSchedule) {
    let data = JSON.parse(JSON.stringify(filingSchedule));
    return FetchHelper.post(window.Api.FilingSchedules, data)
  }

  static deleteFilingSchedule(filingSchedule) {
    return FetchHelper.delete(`${window.Api.FilingSchedules}/${filingSchedule.id}`)
  }

  static updateFilingSchedule(filingSchedule) {
    let data = JSON.parse(JSON.stringify(filingSchedule));
    return FetchHelper.patch(`${window.Api.FilingSchedules}/${filingSchedule.id}`, data)
  }

  static generateReport(report){
    let data = JSON.parse(JSON.stringify(report));
    if(data.filing){
      data.filing = data.filing.id
    }
    if(data.date_from){
      data.date_from = moment(data.date_from).format('YYYY-MM-DD')
    }
    if(data.date_to){
      data.date_to = moment(data.date_to).format('YYYY-MM-DD')
    }
    return FetchHelper.post(window.Api.Reports, data)
  }

  static addPackage(packageObject){
    let data = JSON.parse(JSON.stringify(packageObject));
    data.background_image = data.background_image ? data.background_image.id : null
    data.currency = data.currency ? data.currency.id : null
    return FetchHelper.post(window.Api.Packages, data)
  }

  static updatePackage(packageObject){
    let data = JSON.parse(JSON.stringify(packageObject));
    data.background_image = data.background_image ? data.background_image.id : null
    data.currency = data.currency ? data.currency.id : null
    return FetchHelper.patch(`${window.Api.Packages}/${packageObject.id}`, data)
  }

  static deletePackage(packageObject) {
    return FetchHelper.delete(`${window.Api.Packages}/${packageObject.id}`)
  }

  static getSubscription(subscriptionId){
    return FetchHelper.get(`${window.Api.Subscriptions}/${subscriptionId}`)
  }

  static updateSubscription(subscription){
    let data = JSON.parse(JSON.stringify(subscription));
    data.currency = data.currency.id ? data.currency.id : data.currency
    return FetchHelper.patch(`${window.Api.Subscriptions}/${subscription.company}`, data)
  }

  static getSettings(){
    return FetchHelper.get(`${window.Api.Settings}`)
  }

  static updateSetting(setting){
    let data = JSON.parse(JSON.stringify(setting));
    return FetchHelper.patch(`${window.Api.Settings}/${setting.id}`, data)
  }

  static connectXero(setting){
    return FetchHelper.get(`${window.Api.Settings}/${setting.id}?return_url=${window.location.href}`)
  }

  static createInvoice(invoice){
    let data = JSON.parse(JSON.stringify(invoice));
    data.tax_rate = data.tax_rate ? data.tax_rate.id : null
    return FetchHelper.post(`${window.Api.Invoices}`, data)
  }

  static getInvoice(invoice){
    return FetchHelper.get(`${window.Api.Invoices}/${invoice.id}`)
  }

  static updateInvoice(invoice, status){
    let data = JSON.parse(JSON.stringify(invoice));
    data.tax_rate = data.tax_rate ? data.tax_rate.id : null
    data.status = status
    return FetchHelper.patch(`${window.Api.Invoices}/${invoice.id}`, data)
  }

  static deleteInvoice(invoice){
    return FetchHelper.delete(`${window.Api.Invoices}/${invoice.id}`)
  }

}
