import React from "react";
import Modal from 'react-bootstrap/Modal'
import StickyBox from "react-sticky-box";
import AsyncSelect from "../common/AsyncSelect";
import Select from "../common/Select";
import moment from "moment/moment";
import Flatpickr from "react-flatpickr";
import SmartList from "../common/SmartList";
import Calculations from "../../../utils/Calculations";
import LineItem from "../company/invoice/LineItem";
import Entity from "./Entity";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";
import Currency from "../../../utils/Currency";

export default class Invoice extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {
    if(this.state.invoice.id){
      Backend.getInvoice(this.state.invoice).then(invoice => {
        this.setState({invoice})
      }).catch(e => Notify.error(e.message))
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      invoice: props.invoice ?
        props.invoice :
        {
          status: "draft",
          subscription: props.company.id,
          tax_rate: props.company.subscription.tax_rate,
          subtotal: 0,
          total: 0,
          line_items: [{}]
        },
      company: props.company,
      show: props.show,
    }
  }

  _removeLineItem(lineItem){
    let { invoice } = this.state

    let index = invoice.line_items.indexOf(lineItem)
    invoice.line_items.splice(index, 1)
    this.setState({invoice}, () => this._updateTotals())
  }

  _updateLineItem(lineItem, index){
    let { invoice } = this.state

    invoice.line_items[index] = lineItem

    this.setState({
      invoice,
    }, () => this._updateTotals())
  }

  _updateTotals(){
    let { invoice } = this.state

    invoice.subtotal = Calculations.invoiceSubtotal(invoice.line_items)
    invoice.tax = Calculations.invoiceTax(invoice.subtotal, invoice.tax_rate)
    invoice.total = Calculations.invoiceTotal(invoice.subtotal, invoice.tax)

    this.setState({
      invoice
    })
  }

  _isFormValid(){
    let {
      invoice
    } = this.state

    let error = null

    if(!invoice.tax_rate){
      error = "Please select a tax rate"
    }
    else if(!invoice.due_at){
      error = "Please enter a valid due date"
    }

    if (!error){
      if(invoice.line_items.length < 1){
        error = "Please include at least one line item"
      }else{
        invoice.line_items.map((lineItem, index) => {
          let lineNumber = General.toOrdinal(index+1)
          if(!lineItem.title){
            error = `Please include the title on the ${lineNumber} line item`
          }else if(!lineItem.description){
            error = `Please include the description on the ${lineNumber} line item`
          }else if(lineItem.subtotal === undefined){
            error = `Please include the subtotal on the ${lineNumber} line item`
          }
        })
      }
    }

    if (error) {
      Notify.error(error)
      return false
    }
    return true
  }

  _handleSave(){
    let {
      invoice,
    } = this.state

    if (!this._isFormValid()) {
      return
    }

    if(invoice.id){
      this._updateInvoice()
    }else{
      this._createInvoice()
    }
  }

  _createInvoice(){
    let {
      invoice,
    } = this.state

    this.setState({isLoading: true})

    Backend.createInvoice(invoice).then(invoice => {
      Notify.success("Invoice Created")
      this.setState({invoice, isLoading: false})
    }).catch(e => {
      this.setState({isLoading: false})
      Notify.error(e.message)
    })
  }

  _updateInvoice(){
    let {
      invoice,
    } = this.state

    this.setState({isLoading: true})

    Backend.updateInvoice(invoice, invoice.status).then(invoice => {
      Notify.success("Invoice Updated")
      this.setState({invoice, isLoading: false})
    }).catch(e => {
      this.setState({isLoading: false})
      Notify.error(e.message)
    })
  }

  _sendInvoice(){
    let {
      invoice,
    } = this.state

    if (!this._isFormValid()) {
      return
    }

    this.setState({isLoading: true})

    Backend.updateInvoice(invoice, 'issued').then(response => {
      Notify.success("Invoice Sent")
      this.setState({isLoading: false})
      this.props.onCreated()
    }).catch(e => {
      this.setState({isLoading: false})
      Notify.error(e.message)
    })
  }

  _renderForm(){
    let {
      invoice,
      isLoading
    } = this.state

    let editable = invoice.status === "draft"
    let taxRate = null

    let buttonTitle = 'Save'
    if(invoice.id){
      buttonTitle = 'Update'
    }

    if(invoice.tax_rate){
      taxRate = {
        value: null,
        label: `${parseFloat(invoice.tax_rate?.rate)*100}%`,
        data: taxRate,
      }
    }

    return (
      <>
        <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
          <label className="fw-semibold fs-6 mb-2 required">Due Date</label>
          <div className="input-group date">
            <Flatpickr
              value={invoice.due_at ? invoice.due_at : null}
              disabled={!editable}
              className="form-control form-control-solid"
              placeholder="Due Date"
              options={{
                static: true,
                enableTime: false,
                noCalendar: false,
                dateFormat: "Y-m-d",
                minDate: moment().add(4, 'days').format("YYYY-MM-DD"),
              }}
              onChange={(dates) => {
                invoice.due_at = moment(dates[0]).format("YYYY-MM-DD")
                this.setState({invoice})
              }}
            />
          </div>
        </div>

        <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
          <label className="required fw-semibold fs-6 mb-2">Tax Rate</label>
          <AsyncSelect
            endpoint={window.Api.TaxRates}
            disabled={!editable}
            filter={`standard=true`}
            orderBy={"-rate"}
            className="form-control h-auto border-0 form-control-solid c-selectbox"
            placeholder={'Select Tax Rate'}
            value={taxRate}
            onSelected={(taxRate) => {
              invoice.tax_rate = taxRate
              this.setState({ invoice, error: null }, () => this._updateTotals())
            }}
            getOptions={(taxRates) => {
              return taxRates.map((taxRate) => ({
                value: taxRate.id,
                label: `${parseFloat(taxRate.rate*100).toFixed(2)}%`,
                data: taxRate,
              }))
            }}
          />
        </div>

        <h3>Line Items</h3>

        <div className="position-relative">
          <div className="table-responsive">
            <table className="table table-scroll" style={{overflowX: 'scroll', whiteSpace: 'nowrap'}}>
              <thead>
              <tr>
                <th>Title</th>
                <th>Description</th>
                <th>Subtotal</th>
                <th/>
              </tr>
              </thead>
              <tbody style={{width: '100%'}}>
              {invoice.line_items?.map((lineItem, index) => {
                return (
                  <LineItem
                    key={index}
                    editable={!editable}
                    invoice={invoice}
                    lineItem={lineItem}
                    onUpdated={line_item => this._updateLineItem(line_item, index)}
                    onDelete={line_item => this._removeLineItem(line_item)}
                  />
                )
              })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-8">
            {
              editable &&
              <a
                className="btn btn-light-primary"
                onClick={() => {
                  invoice.line_items.push({quantity:1, taxes: []})
                  this.setState({invoice})
                }}
              >
                Add Line
              </a>
            }
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-7"/>
          <div className="col-lg-5 justify-content-end">
            <div className="calculations">
              <div className="row">
                <div className="col-lg-7 text-start">
                  <h4 className="text-dark">Subtotal:</h4>
                </div>
                <div className="col-lg-5 text-end">
                  <h4 style={{fontWeight: 400}}>{Currency.format(invoice.currency?.symbol, invoice.subtotal)}</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-7 text-start">
                  <h4 className="text-dark">Tax:</h4>
                </div>
                <div className="col-lg-5 text-end">
                  <h4 style={{fontWeight: 400}}>{Currency.format(invoice.currency?.symbol, invoice.tax)}</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-7 text-start">
                  <h4 className="text-dark">Total:</h4>
                </div>
                <div className="col-lg-5 text-end">
                  <h4 style={{fontWeight: 400}}>{Currency.format(invoice.currency?.symbol, invoice.total)}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer px-0">
          <button
            type="button"
            className="btn btn-light me-auto"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          {
            (invoice.id && invoice.status !== 'issued') &&
            <button
              type="button"
              className="btn btn-light-primary"
              onClick={() => this._sendInvoice()}
              disabled={isLoading}
            >
              Send
            </button>
          }

          {
            editable &&
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this._handleSave()}
              disabled={isLoading}
            >
              Save & Preview
            </button>
          }
        </div>
      </>
    )
  }

  _renderFileWindow(file){
    let re = /(?:\.([^.]+))?$/;
    let fileType = re.exec(file.url)[1];

    if(fileType === "pdf"){
      return <iframe src={file.url} width="100%" height="100%"/>
    }
    return (
      <img
        key={file.url}
        src={file.url}
        width="100%" height="100%"
        style={{ objectFit: "contain" }}
      />
    )
  }

  _renderFileView(){
    let {
      show,
      invoice,
      isLoading,
    } = this.state

    return (
      <div className="row">
        <div key={invoice.id} className="col-lg-6 files-preview" style={{ overflowY: 'hidden', minHeight: '100vh' }}>
          <div className="set-viewport-height">

            <div className="file">
              {this._renderFileWindow(invoice.file)}
            </div>

          </div>
        </div>
        <div className="col-lg-6">
          <StickyBox offsetTop={20} style={{width: '100%', background: 'white'}}>
            {this._renderLineItemView()}
          </StickyBox>
        </div>
      </div>
    )
  }

  _renderLineItemView(){
    let {
      show,
      invoice,
      isLoading,
    } = this.state

    return (
      <>
        <Modal.Header closeButton>
          <h2>{invoice.reference ? invoice.reference : 'New Invoice'}</h2>
        </Modal.Header>

        <div className="card">
          <div className="card-body">
            {this._renderForm()}
          </div>
        </div>
      </>
    )
  }

  render() {
    let {
      show,
      invoice,
      isLoading,
    } = this.state

    let buttonTitle = 'Save'
    if(invoice.id){
      buttonTitle = 'Update'
    }

    let editable = invoice.status !== "issued"
    let taxRate = null

    if(invoice.tax_rate){
      taxRate = {
        value: null,
        label: `${parseFloat(invoice.tax_rate?.rate)*100}%`,
        data: taxRate,
      }
    }

    let className = ""
    let dialogClassName = ""
    let size = "lg"
    if(invoice.file){
      className = "modal-digitise"
      dialogClassName = "modal-90vw"
      size = ""
    }

    return (
      <>
        <Modal
          show={show}
          size={size}
          onHide={() => this.props.onHide()}
          dialogClassName={`w-100 ${dialogClassName}`}
          className={className}
        >

          {
            invoice.file ?
              this._renderFileView()
            :
              this._renderLineItemView()
          }

        </Modal>
      </>
    )
  }

}
