import React from "react"

import AuthManager from "../utils/AuthManager";
import Conversations from "./components/messages/Messages";

export default class Messages extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser,
      searchTerm: ""
    }

    this.conversationsList = React.createRef();
  }

  render() {
    let {
      searchTerm,
      selectedConversation
    } = this.state

    return (
      <>
        <Conversations/>
      </>
    )
  }

}
