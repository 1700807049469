import React from "react";

export default class LineItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      editable: props.editable,
      lineItem: props.lineItem,
      invoice: props.invoice,
    }
  }

  render() {
    let {
      record,
      invoice,
      editable,
      lineItem
    } = this.state

    return (
      <>
        <tr>
          <td style={{width: 200}}>
            <input
              key={lineItem}
              type="text"
              className="form-control form-control-solid mb-3 mb-lg-0"
              disabled={editable}
              value={lineItem.title || ''}
              onChange={e => {
                lineItem.title = e.target.value
                this.props.onUpdated(lineItem)
              }}
            />
          </td>
          <td>
            <textarea
              key={lineItem}
              className="form-control form-control-solid mb-3 mb-lg-0"
              rows={1}
              disabled={editable}
              value={lineItem.description || ''}
              onChange={e => {
                lineItem.description = e.target.value
                this.props.onUpdated(lineItem)
              }}
            />
          </td>
          <td style={{width: 100}}>
            <input
              key={lineItem}
              type="number"
              className="form-control form-control-solid mb-3 mb-lg-0"
              style={{width: 100}}
              step={"0.01"}
              disabled={editable}
              value={lineItem.subtotal || ''}
              onChange={e => {
                lineItem.subtotal = e.target.value
                this.props.onUpdated(lineItem)
              }}
            />
          </td>
          {
            (!editable && invoice.line_items.length > 1) &&
            <td className="d-flex">
              <a
                className="btn btn-icon btn-sm btn-light-danger ms-auto mt-1"
                onClick={e => {
                  e.preventDefault()
                  this.props.onDelete(lineItem)
                }}
              >
              <span className="svg-icon svg-icon-1" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="" data-bs-original-title="Reject">
                <i className="fa fa-trash"></i>
              </span>
              </a>
            </td>
          }
        </tr>
      </>
    )
  }

}
