import React from "react"

import moment from "moment"

import {Link} from "react-router-dom";

import Transactions from "./components/tables/Transactions";
import Revisions from "./components/tables/Revisions";
import Card from "./components/structure/Card";

import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import Currency from "../utils/Currency";
import General from "../utils/General";
import Logs from "./components/filings/Logs";
import Dropdown from "react-bootstrap/Dropdown";
import AsyncSelect from "./components/common/AsyncSelect";
import Select from "./components/common/Select";
import Receipt from "./components/modals/Receipt";
import Reports from "./components/tables/Reports";

export default class Filing extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      tabIndex: 0
    }
  }

  componentDidMount(){
    this._load()
  }

  _load(){
    let { filingId } = this.props.match.params
    Backend.getFiling(filingId)
    .then(filing => {
      this.setState({ filing, revisionId: filing.pending_revision })
    })
  }

  _submitFiling(){
    let data = {
      filing: this.state.filing.id,
      status: "committed",
      filing_status: "filed",
      adjustments: []
    }

    Backend.submitFiling(data).then(response => {
      Notify.success("Filing Submitted")
      this.setState({filing: response.latest_filing_data})
    }).catch(e => Notify.error(e.message))
  }

  _renderContent(tabIndex){
    let {
      filing,
      revisionId,
      showDropdown,
      showReceiptModal
    } = this.state

    switch(tabIndex){
      case 0:   return (
        <Transactions
          title={"Allocated Transactions"}
          allocated={true}
          filing={filing}
          revisionId={revisionId}
          onRevisionCreated={revision => this.setState({revisionId: revision.id})}
        />
      )
      case 1:   return (
        <Transactions
          title={"Unallocated Transactions"}
          unallocated={true}
          filing={filing}
          revisionId={revisionId}
          onRevisionCreated={revision => this.setState({revisionId: revision.id})}
        />
      )
      case 2:   return (
        <Revisions
          type={"allocated"}
          filing={filing}
          revisionId={revisionId}
          onRevisionDeleted={() => this.setState({revisionId: null, tabIndex: 0})}
          onRevisionCommitted={() => this.setState({revisionId: null, tabIndex: 0})}
        />
      )
      case 3:   return (
        <Reports
          filing={filing}
        />
      )
      case 4:   return (
        <Logs
          filing={filing}
        />
      )
      default:  return null
    }
  }

  render() {
    let {
      type,
      filing,
      tabIndex,
      revisionId,
      showDropdown,
      showReceiptModal
    } = this.state

    if(!filing){
      return null
    }

    let filingLabelClassName = 'badge-light-dark'
    let label = `${filing.tax_number.country_code} ${filing.type.toUpperCase()}`
    if(filing.type === "oss"){
      filingLabelClassName = 'badge-light-info'
    }else if(filing.type === "ioss"){
      filingLabelClassName = 'badge-light-success'
    }

    if(filing.type === "oss" || filing.type === "ioss"){
      label = `${filing.type.toUpperCase()}`
    }

    return (
      <>

        {
          filing.recalculating_stats &&
          <div className="alert alert-warning" role="alert">
            Currency conversion in progress!
          </div>
        }

        <div className="d-flex flex-column flex-xl-row">
          <div className="d-flex flex-column flex-xl-row">
            <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
              <div className="card mb-5 mb-xl-8">
                <div className="card-header bg-primary">

                  <div className="px-9 pt-7 card-rounded h-225px w-100 bg-primary">
                    <div className="d-flex flex-stack">
                    </div>
                    <div className="d-flex text-center flex-column text-white pt-8">
                      <span className="fw-bold fs-7">NET</span>
                      <span className="fw-bolder fs-2x pt-1">{Currency.format(filing.currency.symbol, filing.net)}</span>
                    </div>
                  </div>


                  <div
                    className="bg-body shadow-sm card-rounded mx-4 w-100 mb-n12 px-6 py-9 position-relative z-index-1"
                    style={{marginTop: -100}}
                  >
                    <div className="d-flex align-items-center mb-6">
                      <div className="d-flex align-items-center flex-wrap w-100">
                        <div className="mb-1 pe-3 flex-grow-1">
                          <a href="#" className="fs-7 text-gray-800 text-hover-primary fw-bolder">Tax Period</a>
                          <div className="text-gray-400 fw-bold fs-8">{filing.transactions_no} Transactions</div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="fw-bolder fs-7 text-gray-800 pe-1">{filing.period}</div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-6">
                      <div className="d-flex align-items-center flex-wrap w-100">
                        <div className="mb-1 pe-3 flex-grow-1">
                          <a href="#" className="fs-7 text-gray-800 text-hover-primary fw-bolder">Tax On Sales</a>
                          <div className="text-gray-400 fw-bold fs-8">{filing.sales_no} Sales</div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="fw-bolder fs-7 text-gray-800 pe-1">{Currency.format(filing.currency.symbol, filing.sales_tax)}</div>
                          <span className="svg-icon svg-icon-5 svg-icon-success ms-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                              <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1"
                                    transform="rotate(90 13 6)" fill="black"></rect>
                              <path
                                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                fill="black"></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-6">
                      <div className="d-flex align-items-center flex-wrap w-100">
                        <div className="mb-1 pe-3 flex-grow-1">
                          <a href="#" className="fs-7 text-gray-800 text-hover-primary fw-bolder">Tax On Purchases</a>
                          <div className="text-gray-400 fw-bold fs-8">{filing.purchases_no} Purchases</div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="fw-bolder fs-7 text-gray-800 pe-1">{Currency.format(filing.currency.symbol, filing.purchases_tax)}</div>
                          <span className="svg-icon svg-icon-5 svg-icon-danger ms-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                              <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1"
                                    transform="rotate(-90 11 18)" fill="black"></rect>
                              <path
                                d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                fill="black"></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="d-flex flex-center flex-column py-5">
                    <div className="mb-9 mt-9">
                      <div className={`badge badge-lg ${filingLabelClassName} d-inline`}>{ label }</div>
                    </div>
                  </div>
                  <div className="d-flex flex-stack fs-4 py-3">
                    <div className="fw-bolder rotate collapsible" data-bs-toggle="collapse">Details
                    </div>
                  </div>
                  <div className="separator"></div>
                  <div id="kt_user_view_details" className="collapse show">
                    <div className="pb-5 fs-6">
                      <div className="fw-bolder mt-5">Status</div>
                      <div className="text-gray-600">{General.snakeCaseToTitleCase(filing.status)}</div>
                      <div className="fw-bolder mt-5">Period</div>
                      <div className="text-gray-600">{moment(filing.period_start).format('Do MMM YYYY')} to {moment(filing.period_end).format('Do MMM YYYY')}</div>
                      <div className="fw-bolder mt-5">Last Updated</div>
                      <div className="text-gray-600">{moment(filing.updated_at).format("Do MMM YYYY")}</div>
                      <div className="fw-bolder mt-5">Date Due</div>
                      <div className="text-gray-600">{moment(filing.period_end).add(1, 'month').endOf('month').format("Do MMM YYYY")}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-lg-row-fluid ms-lg-15">
            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
              <li className="nav-item">
                <a
                  className={`nav-link text-active-primary pb-4 ${tabIndex === 0 && 'active'}`}
                  onClick={() => {
                    this.setState({tabIndex: 0})
                  }}
                >
                  Overview
                </a>
              </li>
              {
                (AuthManager.currentUser.user.role === "agent" && filing.status !== "filed") &&
                <>
                  <li className="nav-item">
                    <a
                      className={`nav-link text-active-primary pb-4 ${tabIndex === 1 && 'active'}`}
                      onClick={() => {
                        this.setState({tabIndex: 1})
                      }}
                    >
                      Unallocated
                    </a>
                  </li>
                  {
                    revisionId &&
                    <li className="nav-item">
                      <a
                        className={`nav-link text-active-primary pb-4 ${tabIndex === 2 && 'active'}`}
                        onClick={() => {
                          this.setState({tabIndex: 2})
                        }}
                      >
                        Review Changes
                      </a>
                    </li>
                  }
                </>
              }
              <li className="nav-item">
                <a
                  className={`nav-link text-active-primary pb-4 ${tabIndex === 3 && 'active'}`}
                  onClick={() => {
                    this.setState({tabIndex: 3})
                  }}
                >
                  Reports
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link text-active-primary pb-4 ${tabIndex === 4 && 'active'}`}
                  onClick={() => {
                    this.setState({tabIndex: 4})
                  }}
                >
                  Events & Logs
                </a>
              </li>
              {
                AuthManager.currentUser.user.role === "agent" &&
                <li className="nav-item ms-auto">
                  <Dropdown className="d-inline mx-2" autoClose="outside" show={showDropdown} onToggle={() => this.setState({ showDropdown: !showDropdown})}>
                    <Dropdown.Toggle id="dropdown-autoclose-outside" variant={"primary"} onClick={() => this.setState({ showDropdown: true})}>
                      Actions
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{width: 180}}
                    >
                      <div className="menu-item px-5">
                        <div className="menu-content text-muted pb-2 px-5 fs-7 text-uppercase">Settings</div>
                      </div>
                      {
                        (filing.status !== "filed" && moment() > moment(filing.period_end)) &&
                        <div className="menu-item px-5">
                          <a
                            href="#"
                            className="menu-link px-5"
                            onClick={ e => {
                              e.preventDefault()
                              this.setState({showReceiptModal: true})
                            }}
                          >
                            Mark as Filed
                          </a>
                        </div>
                      }
                      {
                        (filing.status === "filed" && (filing.payment_status !== "refunded" && filing.payment_status !== "paid")) &&
                        <div className="menu-item px-5">
                          <a
                            href="#"
                            className="menu-link px-5"
                            onClick={ e => {
                              e.preventDefault()
                              this.setState({showReceiptModal: true, type: 'receipt'})
                            }}
                          >
                            Mark as Paid
                          </a>
                        </div>
                      }
                      {
                        (filing.payment_status === "refunded" || filing.payment_status === "paid") &&
                        <div className="menu-item px-5">
                          <a
                            href={filing.file?.url}
                            className="menu-link px-5"
                            target="_blank"
                          >
                            View Receipt
                          </a>
                        </div>
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              }
            </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" role="tabpanel">
                {this._renderContent(tabIndex)}
              </div>
            </div>
          </div>
        </div>
        {
          showReceiptModal &&
          <Receipt
            type={type}
            show={showReceiptModal}
            filing={filing}
            onUploaded={(filing) => this.setState({filing, showReceiptModal: false, type: null})}
            onHide={() => this.setState({showReceiptModal: false, type: null})}
          />
        }
      </>
    )
  }

}
