import React from "react";
import { withRouter } from "react-router-dom";
import Event from "../../../utils/Event"
import AuthManager from "../../../utils/AuthManager";
import Permissions from "../../../utils/Permissions";

const LOGO_DARK = require("../../../assets/media/logos/logo-dark.png")

const LINK_DASHBOARD = {
  title: "Dashboard",
  icon: (
    <>
      <rect x="2" y="2" width="9" height="9" rx="2" fill="black" />
      <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="black" />
      <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="black" />
      <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="black" />
    </>
  ),
  url: "/",
}

const LINK_FILINGS = {
  title: 'Filings',
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24"/>
      <path d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z" fill="#000000"/>
    </g>
  ),
  url: "/filings",
  requiredPermission: Permissions.FILINGS
}

const LINK_DOCUMENTS = {
  title: 'Documents',
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24"/>
      <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
      <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1"/>
      <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1"/>
    </g>
  ),
  url: "/documents",
  requiredPermission: Permissions.DOCUMENTS
}

const LINK_MESSAGES = {
  title: 'Messages',
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24"/>
      <path d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z" fill="#000000"/>
      <path d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z" fill="#000000" opacity="0.3"/>
    </g>
  ),
  url: "/messages",
  requiredPermission: Permissions.MESSAGES
}

const LINK_COMPANIES = {
  title: 'Companies',
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <path d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z" fill="#000000"></path>
      <rect fill="#FFFFFF" x="13" y="8" width="3" height="3" rx="1"></rect>
      <path d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z" fill="#000000" opacity="0.3"></path>
    </g>
  ),
  url: "/companies",
  requiredPermission: Permissions.FILINGS
}

const LINK_ALL_TRANSACTIONS = {
  title: 'All',
  url: '/transactions',
  requiredPermission: Permissions.TRANSACTIONS
}

const LINK_SALES_TRANSACTIONS = {
  title: 'Sales',
  url: '/sales',
  requiredPermission: Permissions.TRANSACTIONS
}

const LINK_PURCHASES_TRANSACTIONS = {
  title: 'Purchases',
  url: '/purchases',
  requiredPermission: Permissions.TRANSACTIONS
}

const LINK_HIGH_RISK_TRANSACTIONS = {
  title: 'High Risk',
  url: '/high-risk',
  requiredPermission: Permissions.TRANSACTIONS
}

const LINK_RULES = {
  title: 'Rules',
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <rect fill="#000000" opacity="0.3" x="12" y="7" width="10" height="2" rx="1"></rect>
      <path d="M2,9 C1.44771525,9 1,8.55228475 1,8 C1,7.44771525 1.44771525,7 2,7 L7.35012691,7 C8.14050434,7 8.85674733,7.46546704 9.17775001,8.18772307 L12.6498731,16 L22,16 C22.5522847,16 23,16.4477153 23,17 C23,17.5522847 22.5522847,18 22,18 L12.6498731,18 C11.8594957,18 11.1432527,17.534533 10.82225,16.8122769 L7.35012691,9 L2,9 Z" fill="#000000" fill-rule="nonzero"></path>
    </g>
  ),
  url: '/rules'
}

const LINK_TEAM = {
  title: 'Team',
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24"/>
      <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
      <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
    </g>
  ),
  url: '/team'
}

const LINK_SETTINGS = {
  title: "Settings",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24"/>
      <path d="M5,8.6862915 L5,5 L8.6862915,5 L11.5857864,2.10050506 L14.4852814,5 L19,5 L19,9.51471863 L21.4852814,12 L19,14.4852814 L19,19 L14.4852814,19 L11.5857864,21.8994949 L8.6862915,19 L5,19 L5,15.3137085 L1.6862915,12 L5,8.6862915 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z" fill="#000000"/>
    </g>
  ),
  url: "/settings"
}

const LINK_ROLES = {
  title: "Roles",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
        fill="#000000"
        opacity="0.3"
      />
      <path
        d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z"
        fill="#000000"
        opacity="0.3"
      />
      <path
        d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z"
        fill="#000000"
        opacity="0.3"
      />
    </g>
  ),
  url: "/roles",
};

const LINK_FILING_SCHEDULES = {
  title: "Filing Schedules",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24"/>
      <rect fill="#000000" opacity="0.3" x="4" y="4" width="4" height="4" rx="1"/>
      <path d="M5,10 L7,10 C7.55228475,10 8,10.4477153 8,11 L8,13 C8,13.5522847 7.55228475,14 7,14 L5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 L14,7 C14,7.55228475 13.5522847,8 13,8 L11,8 C10.4477153,8 10,7.55228475 10,7 L10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,10 L13,10 C13.5522847,10 14,10.4477153 14,11 L14,13 C14,13.5522847 13.5522847,14 13,14 L11,14 C10.4477153,14 10,13.5522847 10,13 L10,11 C10,10.4477153 10.4477153,10 11,10 Z M17,4 L19,4 C19.5522847,4 20,4.44771525 20,5 L20,7 C20,7.55228475 19.5522847,8 19,8 L17,8 C16.4477153,8 16,7.55228475 16,7 L16,5 C16,4.44771525 16.4477153,4 17,4 Z M17,10 L19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 L17,14 C16.4477153,14 16,13.5522847 16,13 L16,11 C16,10.4477153 16.4477153,10 17,10 Z M5,16 L7,16 C7.55228475,16 8,16.4477153 8,17 L8,19 C8,19.5522847 7.55228475,20 7,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,17 C4,16.4477153 4.44771525,16 5,16 Z M11,16 L13,16 C13.5522847,16 14,16.4477153 14,17 L14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 L10,17 C10,16.4477153 10.4477153,16 11,16 Z M17,16 L19,16 C19.5522847,16 20,16.4477153 20,17 L20,19 C20,19.5522847 19.5522847,20 19,20 L17,20 C16.4477153,20 16,19.5522847 16,19 L16,17 C16,16.4477153 16.4477153,16 17,16 Z" fill="#000000"/>
    </g>
  ),
  url: "/filing-schedules",
};

const LINK_PACKAGES = {
  title: "Packages",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <path d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
      <path d="M3.28077641,9 L20.7192236,9 C21.2715083,9 21.7192236,9.44771525 21.7192236,10 C21.7192236,10.0817618 21.7091962,10.163215 21.6893661,10.2425356 L19.5680983,18.7276069 C19.234223,20.0631079 18.0342737,21 16.6576708,21 L7.34232922,21 C5.96572629,21 4.76577697,20.0631079 4.43190172,18.7276069 L2.31063391,10.2425356 C2.17668518,9.70674072 2.50244587,9.16380623 3.03824078,9.0298575 C3.11756139,9.01002735 3.1990146,9 3.28077641,9 Z M12,12 C11.4477153,12 11,12.4477153 11,13 L11,17 C11,17.5522847 11.4477153,18 12,18 C12.5522847,18 13,17.5522847 13,17 L13,13 C13,12.4477153 12.5522847,12 12,12 Z M6.96472382,12.1362967 C6.43125772,12.2792385 6.11467523,12.8275755 6.25761704,13.3610416 L7.29289322,17.2247449 C7.43583503,17.758211 7.98417199,18.0747935 8.51763809,17.9318517 C9.05110419,17.7889098 9.36768668,17.2405729 9.22474487,16.7071068 L8.18946869,12.8434035 C8.04652688,12.3099374 7.49818992,11.9933549 6.96472382,12.1362967 Z M17.0352762,12.1362967 C16.5018101,11.9933549 15.9534731,12.3099374 15.8105313,12.8434035 L14.7752551,16.7071068 C14.6323133,17.2405729 14.9488958,17.7889098 15.4823619,17.9318517 C16.015828,18.0747935 16.564165,17.758211 16.7071068,17.2247449 L17.742383,13.3610416 C17.8853248,12.8275755 17.5687423,12.2792385 17.0352762,12.1362967 Z" fill="#000000"></path>
    </g>
  ),
  url: "/packages",
};

const SECTIONS = [
  {
    title: '',
    links: [
      LINK_DASHBOARD,
    ]
  },
  {
    title: 'Core',
    links: [
      LINK_COMPANIES,
      LINK_FILINGS,

    ]
  },
  {
    title: '',
    name: 'Transactions',
    type: 'menu',
    requiredPermission: Permissions.TRANSACTIONS,
    icon: (
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <path d="M13,5 L15,5 L15,20 L13,20 L13,5 Z M5,5 L5,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,6 C2,5.44771525 2.44771525,5 3,5 L5,5 Z M16,5 L18,5 L18,20 L16,20 L16,5 Z M20,5 L21,5 C21.5522847,5 22,5.44771525 22,6 L22,19 C22,19.5522847 21.5522847,20 21,20 L20,20 L20,5 Z" fill="#000000"/>
        <polygon fill="#000000" opacity="0.3" points="9 5 9 20 7 20 7 5"/>
      </g>
    ),
    links: [
      LINK_ALL_TRANSACTIONS,
      LINK_SALES_TRANSACTIONS,
      LINK_PURCHASES_TRANSACTIONS,
      LINK_HIGH_RISK_TRANSACTIONS,
    ]
  },
  {
    title: '',
    icon: (
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <path d="M13,5 L15,5 L15,20 L13,20 L13,5 Z M5,5 L5,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,6 C2,5.44771525 2.44771525,5 3,5 L5,5 Z M16,5 L18,5 L18,20 L16,20 L16,5 Z M20,5 L21,5 C21.5522847,5 22,5.44771525 22,6 L22,19 C22,19.5522847 21.5522847,20 21,20 L20,20 L20,5 Z" fill="#000000"/>
        <polygon fill="#000000" opacity="0.3" points="9 5 9 20 7 20 7 5"/>
      </g>
    ),
    links: [
      LINK_DOCUMENTS,
    ]
  },
  {
    title: "Settings",
    links: [
      LINK_PACKAGES,
      LINK_RULES,
      LINK_FILING_SCHEDULES,
      LINK_ROLES,
      LINK_TEAM,
      LINK_SETTINGS
    ]
  },
]

class LeftMenu extends React.Component {

  constructor(props) {
    super(props);

    let sections = SECTIONS;

    if(AuthManager.currentUser.user.role === "agent"){
      sections[4].links.splice(0,3)
    }
    //
    // if(AuthManager.currentUser.user.role === "admin"){
    //   sections[1].links.splice(2,1)
    // }

    this.state = {
      sections
    }

    this._setupEvents();
  }

  _setupEvents() {
    Event.on("ADD_DECLARATION", () => {
      this.setState({ showFormsModal: true });
    });
  }

  _renderSections() {
    let {sections, isLoading} = this.state;

    return (
      <>
        <div
          className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
          id="#kt_aside_menu" data-kt-menu="true"
        >
          <div className="menu-item mt-10">
            <div className="menu-content pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1"> </span>
            </div>
          </div>
          {
            sections.map(section => {
              return (
                <>
                  {
                    section.title &&
                    <>
                      <div className="menu-item">
                        <div className="menu-content pt-8 pb-2">
                          <span className="menu-section text-muted text-uppercase fs-8 ls-1">{section.title}</span>
                        </div>
                      </div>
                    </>
                  }

                  {
                    (section.type === "menu" && Permissions.hasViewPermission(section.requiredPermission)) &&
                    <>
                      <div data-kt-menu-trigger="click" className="menu-item menu-accordion ">
                        <span className="menu-link">
                          <span className="menu-icon">
                            <span className="svg-icon svg-icon-2">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                {section.icon}
                              </svg>
                            </span>
                          </span>
                          <span className="menu-title">{section.name}</span>
                          <span className="menu-arrow"></span>
                        </span>
                        <div className="menu-sub menu-sub-accordion menu-active-bg ">
                          {
                            section.links.map(link => {
                              return (
                                <div className="menu-item">
                                  <a className="menu-link" onClick={() => this.props.history.push(link.url)}>
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot"></span>
                                    </span>
                                    <span className="menu-title">{link.title}</span>
                                  </a>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </>
                  }

                  {
                    section.type !== "menu" && section.links.map(link => {
                      if(link.requiredPermission){
                        if(!Permissions.hasViewPermission(link.requiredPermission)){
                          return null
                        }
                      }
                      let className = "menu-link";
                      if (link.url === window.location.pathname) {
                        className += " active";
                      }
                      return (
                        <div className="menu-item">
                          <a
                            className={className}
                            onClick={() => this.props.history.push(link.url)}
                          >
                            <span className="menu-icon">
                              <span className="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  {link.icon}
                                </svg>
                              </span>
                            </span>
                            <span className="menu-title">{link.title}</span>
                          </a>
                        </div>
                      )
                    })
                  }
                </>
              )
            })
          }
        </div>
      </>
    )
  }

  render() {
    return (
      <>
        <div id="kt_aside" className="aside aside-dark aside-hoverable" data-kt-drawer="true"
             data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}"
             data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}"
             data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_mobile_toggle">
          <div className="aside-logo flex-column-auto" id="kt_aside_logo">
            <a onClick={() => this.props.history.push('/')}>
              <img alt="Logo" src={LOGO_DARK} className="h-25px logo"/>
            </a>
            <div id="kt_aside_toggle" className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
                 data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body"
                 data-kt-toggle-name="aside-minimize">
              <span className="svg-icon svg-icon-1 rotate-180">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.5"
                        d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
                        fill="black"></path>
                  <path
                    d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
                    fill="black"></path>
                </svg>
              </span>
            </div>
          </div>
          <div className="aside-menu flex-column-fluid">
            <div className="hover-scroll-overlay-y my-5 my-lg-5" id="kt_aside_menu_wrapper" data-kt-scroll="true"
                 data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-height="auto"
                 data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer" data-kt-scroll-wrappers="#kt_aside_menu"
                 data-kt-scroll-offset="0" style={{height: 930}}
            >
              {this._renderSections()}
            </div>
          </div>
        </div>
      </>
    )
  }

}

export default withRouter(LeftMenu);
