import React from "react";
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import LocationInput from "../common/LocationInput";


export default class Profile extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      selectedIndex: props.selectedIndex,
      company: props.company,
    }
  }

  componentDidMount() {

  }

  _isValid(){
    let {
      company
    } = this.state

    let error = null

    if (!company.name) {
      error = "Please enter a company name"
    }

    if (error) {
      Notify.error(error)
      return false
    }
    return true
  }

  _onUpdateClick(){
    let {
      company
    } = this.state

    if(!this._isValid()){
      return
    }

    this.setState({loading: true})

    Backend.updateCompany(company).then(response => {
      this.setState({loading: false})
      Notify.success("Profile Updated")
      this.props.onSuccess(response)
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  render() {
    let {
      show,
      company,
      loading,
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={''}
          dialogClassName={"modal-dialog modal-dialog-centered mw-700px"}
        >
          <Modal.Header>
            <h2 className="w-100">Manage Profile</h2>
          </Modal.Header>

          <Modal.Body>

            <div className="form fv-plugins-bootstrap5 fv-plugins-framework">

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-1">Company Name</label>
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Included Transactions Threshold"
                    value={company.name}
                    className="form-control form-control-solid"
                    onChange={e => {
                      company.name = e.target.value
                      this.setState({company})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-1">Business Address</label>
                <div className="position-relative">
                  <LocationInput
                    className="form-control form-control-lg form-control-solid"
                    location={company.info.business_address || ""}
                    placeholder={""}
                    onBlur={() => null}
                    allowManualInput={true}
                    onUpdated={location => {
                      company.info.business_address = location
                      this.setState({company})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="text-end pt-15">
                <a
                  className="btn btn-light me-3"
                  onClick={() => this.props.onHide()}
                >
                  Cancel
                </a>

                <button
                  className="btn btn-primary"
                  disabled={loading}
                  onClick={() => this._onUpdateClick()}
                >
                  <span className="indicator-label">
                    Update
                  </span>
                </button>
              </div>

            </div>

          </Modal.Body>

        </Modal>
      </>
    )
  }

}
