import Calculations from "../../../utils/Calculations";
import React from "react";
import AsyncSelect from "../common/AsyncSelect";

export default class LineItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      editable: props.editable,
      lineItem: props.lineItem,
    }
  }

  render() {
    let {
      record,
      editable,
      lineItem
    } = this.state

    let taxRate = null

    if(lineItem.taxes[0] && lineItem.taxes[0].rate != null){
      taxRate = {
        value: null,
        label: `${parseFloat(lineItem.taxes[0].rate)*100}%`,
        data: taxRate,
      }
    }

    if(lineItem.taxes[0] && lineItem.taxes[0].tax_rate != null){
      taxRate = {
        value: null,
        label: `${parseFloat(lineItem.taxes[0]?.tax_rate?.rate)*100}%`,
        data: taxRate,
      }
    }

    return (
      <>
        <tr>
          <td style={{width: 200}}>
            <textarea
              className="form-control form-control-solid mb-3 mb-lg-0"
              style={{width: 200}}
              rows={1}
              disabled={editable}
              value={lineItem.description}
              onChange={e => {
                lineItem.description = e.target.value
                this.props.onUpdated(lineItem)
              }}
            />
          </td>
          <td style={{width: 100}}>
            <input
              type="number"
              className="form-control form-control-solid mb-3 mb-lg-0"
              style={{width: 100}}
              step={"0.01"}
              disabled={editable}
              value={lineItem.subtotal}
              onChange={e => {
                lineItem.subtotal = e.target.value
                this.props.onUpdated(lineItem)
              }}
            />
          </td>
          <td style={{width: 120}}>
            <AsyncSelect
              classNamePrefix={'select-component'}
              endpoint={`${window.Api.TaxRates}`}
              filter={`standard=true`}
              disabled={editable}
              orderBy={""}
              className="form-control border-0 form-control-solid c-selectbox mb-lg-0"
              placeholder={'Tax Rate'}
              value={taxRate}
              onSelected={(taxRate) => {
                lineItem.taxes[0] = {tax: Calculations.totalVat(lineItem.quantity, lineItem.subtotal, parseFloat(taxRate.rate)), rate: parseFloat(taxRate.rate)}
                this.props.onUpdated(lineItem)
              }}
              getOptions={(taxRates) => {
                return taxRates.map((taxRate) => ({
                  value: taxRate.id,
                  label: `${parseFloat(taxRate.rate)*100}%`,
                  data: taxRate,
                }))
              }}
              styles={{
                container: (styles) => ({
                  ...styles,
                  width: 120,
                  minWidth: 120,
                  position: 'static',
                  padding: '2.2px !important'
                }),
                menu: (styles) => ({
                  ...styles,
                  maxWidth: 100,
                  top: 112,
                  position: 'static'
                })
              }}
            />
          </td>
          <td style={{width: 100}}>
            <input
              type="number"
              className="form-control form-control-solid mb-3 mb-lg-0"
              style={{width: 100}}
              step={"0.01"}
              disabled={editable}
              value={lineItem.tax}
              onChange={e => {
                lineItem.tax = e.target.value
                lineItem.total = e.target.value + lineItem.subtotal
                this.props.onUpdated(lineItem)
              }}
            />
          </td>
          <td style={{width: 100}}>
            <input
              type="number"
              className="form-control form-control-solid mb-3 mb-lg-0"
              style={{width: 100}}
              step={"0.01"}
              disabled={editable}
              value={lineItem.total}
              onChange={e => {
                lineItem.total = e.target.value
                this.props.onUpdated(lineItem)
              }}
            />
          </td>
          {
            !editable &&
            <td>
              <a
                className="btn btn-icon btn-sm btn-light-danger me-2 mt-1"
                onClick={e => {
                  e.preventDefault()
                  this.props.onDelete(lineItem)
                }}
              >
              <span className="svg-icon svg-icon-1" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="" data-bs-original-title="Reject">
                <i className="fa fa-trash"></i>
              </span>
              </a>
            </td>
          }
        </tr>
      </>
    )
  }

}
