import React from "react"

import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";
import General from "../utils/General";
import Event from "../utils/Event";
import {Link} from "react-router-dom";
import Overview from "./components/company/Overview";
import Transactions from "./components/tables/Transactions";
import FilingsTable from "./components/tables/Filings";
import Stores from "./components/company/Stores";
import Profile from "./components/company/Profile";
import CompanyMembers from "./components/tables/CompanyMembers";
import Messages from "./components/messages/Messages";
import Permissions from "../utils/Permissions";
import Payments from "./components/company/Payments";
import TaxDetails from "./components/tables/TaxNumbers";
import Reports from "./components/tables/Reports";
import Subscription from "./components/company/Subscription";

export default class Company extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      selectedTab: window.location.hash ? window.location.hash.substring(1) : 'overview',
      currentUser: AuthManager.currentUser
    }
  }

  componentDidMount(){
    this._load()
  }

  _load(){
    let { companyId } = this.props.match.params
    Backend.getCompany(companyId)
      .then(company => {
        this.setState({ company })
      })
  }

  render() {
    let {
      company,
      selectedTab
    } = this.state

    if(!company) return null

    let onboardingStatus = company.info.status
    let className = 'badge-light-success'
    let status = "Complete"

    if(onboardingStatus === "detail"){
      className = 'badge-light-warning'
      status = "Tax Number"
    }

    if(onboardingStatus === "tax"){
      if(!company.info.tax_no){
        className = 'badge-light-dark'
        status = "Tax Info"
      }
      else{
        className = 'badge-light-primary'
        status = "EORI Number"
      }
    }

    if(onboardingStatus === "integration"){
      className = "badge-light-danger"
      status = "Shopify"
    }

    return (
      <>
        <div className="card mb-5 mb-xl-10">
          <div className="card-body pt-9 pb-0">
            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
              <div className="flex-grow-1">
                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center mb-2">
                      <a href="#" className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">
                        {company.name}
                      </a>
                      <span className={`badge ${className} badge-sm fs-base ms-2`}>
                        {status}
                      </span>
                    </div>
                    <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                      <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                        <span className="svg-icon svg-icon-4 me-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                               fill="none">
                            <path
                              opacity="0.3"
                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                              fill="black"
                            />
                            <path
                              d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {company.created_by.user.email}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex overflow-auto h-55px">
              <ul
                className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                <li className="nav-item">
                  <a className={`nav-link text-active-primary me-6 ${selectedTab === 'overview' ? 'active' : ''}`} data-bs-toggle="tab" href="#overview">Overview</a>
                </li>
                {
                  Permissions.hasViewPermission(Permissions.FILINGS) &&
                  <li className="nav-item">
                    <a className={`nav-link text-active-primary me-6 ${selectedTab === 'filings' ? 'active' : ''}`} data-bs-toggle="tab" href="#filings">Filings</a>
                  </li>
                }
                {
                  Permissions.hasViewPermission(Permissions.TRANSACTIONS) &&
                  <li className="nav-item">
                    <a className={`nav-link text-active-primary me-6 ${selectedTab === 'transactions' ? 'active' : ''}`} data-bs-toggle="tab" href="#transactions">Transactions</a>
                  </li>
                }
                {
                  Permissions.hasViewPermission(Permissions.MESSAGES) &&
                  <li className="nav-item">
                    <a className={`nav-link text-active-primary me-6 ${selectedTab === 'messages' ? 'active' : ''}`} data-bs-toggle="tab" href="#messages">Messages</a>
                  </li>
                }
                {
                  Permissions.hasViewPermission(Permissions.INTEGRATIONS) &&
                  <li className="nav-item">
                    <a className={`nav-link text-active-primary me-6 ${selectedTab === 'stores' ? 'active' : ''}`} data-bs-toggle="tab" href="#stores">Stores</a>
                  </li>
                }
                {
                  Permissions.hasViewPermission(Permissions.PAYMENTS) &&
                  <li className="nav-item">
                    <a className={`nav-link text-active-primary me-6 ${selectedTab === 'payments' ? 'active' : ''}`} data-bs-toggle="tab" href="#payments">Payments</a>
                  </li>
                }
                {
                  Permissions.hasViewPermission(Permissions.TAXES) &&
                  <li className="nav-item">
                    <a className={`nav-link text-active-primary me-6 ${selectedTab === 'tax' ? 'active' : ''}`} data-bs-toggle="tab" href="#tax">Tax Numbers</a>
                  </li>
                }
                {
                  Permissions.hasViewPermission(Permissions.REPORTS) &&
                  <li className="nav-item">
                    <a className={`nav-link text-active-primary me-6 ${selectedTab === 'reports' ? 'active' : ''}`} data-bs-toggle="tab" href="#reports">Reports</a>
                  </li>
                }
                <li className="nav-item">
                  <a className={`nav-link text-active-primary me-6 ${selectedTab === 'profile' ? 'active' : ''}`} data-bs-toggle="tab" href="#profile">Profile</a>
                </li>
                {
                  AuthManager.currentUser.user.role === "admin" &&
                  <>
                    <li className="nav-item">
                      <a className={`nav-link text-active-primary me-6 ${selectedTab === 'team' ? 'active' : ''}`} data-bs-toggle="tab" href="#team">Team</a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link text-active-primary me-6 ${selectedTab === 'subscription' ? 'active' : ''}`} data-bs-toggle="tab" href="#subscription">Subscription</a>
                    </li>
                  </>
                }
              </ul>
            </div>
          </div>
        </div>

        <div className="tab-content" id="myTabContent">
          <div className={`tab-pane fade ${selectedTab === 'overview' ? 'show active' : ''}`} id="overview" role="tabpanel">
            <Overview
              company={company}
            />
            {
              Permissions.hasViewPermission(Permissions.TRANSACTIONS) &&
              <Transactions
                title={"Transactions"}
                companyId={company.id}
              />
            }
          </div>
          {
            Permissions.hasViewPermission(Permissions.FILINGS) &&
            <div className={`tab-pane fade ${selectedTab === 'filings' ? 'show active' : ''}`} id="filings" role="tabpanel">
              <FilingsTable
                companyId={company.id}
              />
            </div>
          }
          {
            Permissions.hasViewPermission(Permissions.TRANSACTIONS) &&
            <div className={`tab-pane fade ${selectedTab === 'transactions' ? 'show active' : ''}`} id="transactions" role="tabpanel">
              <Transactions
                title={"Transactions"}
                companyId={company.id}
              />
            </div>
          }
          {
            Permissions.hasViewPermission(Permissions.MESSAGES) &&
            <div className={`tab-pane fade ${selectedTab === 'messages' ? 'show active' : ''}`} id="messages" role="tabpanel">
              <Messages companyId={company.id}/>
            </div>
          }
          {
            Permissions.hasViewPermission(Permissions.INTEGRATIONS) &&
            <div className={`tab-pane fade ${selectedTab === 'stores' ? 'show active' : ''}`} id="stores" role="tabpanel">
              <Stores companyId={company.id} />
            </div>
          }
          {
            Permissions.hasViewPermission(Permissions.PAYMENTS) &&
            <div className={`tab-pane fade ${selectedTab === 'payments' ? 'show active' : ''}`} id="payments" role="tabpanel">
              <Payments companyId={company.id} bankAccountConnected={company.bank_account_connected} />
            </div>
          }
          {
            Permissions.hasViewPermission(Permissions.TAXES) &&
            <div className={`tab-pane fade ${selectedTab === 'tax' ? 'show active' : ''}`} id="tax" role="tabpanel">
              <TaxDetails company={company}/>
            </div>
          }
          {
            Permissions.hasViewPermission(Permissions.REPORTS) &&
            <div className={`tab-pane fade ${selectedTab === 'reports' ? 'show active' : ''}`} id="reports" role="tabpanel">
              <Reports company={company}/>
            </div>
          }
          <div className={`tab-pane fade ${selectedTab === 'profile' ? 'show active' : ''}`} id="profile" role="tabpanel">
            <Profile
              company={company}
              onSuccess={company => this.setState({company})}
            />
          </div>
          {
            AuthManager.currentUser.user.role === "admin" &&
            <>
              <div className={`tab-pane fade ${selectedTab === 'team' ? 'show active' : ''}`} id="team" role="tabpanel">
                <CompanyMembers companyId={company.id} />
              </div>
              <div className={`tab-pane fade ${selectedTab === 'subscription' ? 'show active' : ''}`} id="subscription" role="tabpanel">
                <Subscription
                  company={company}
                  onSuccess={company => this.setState({company})}
                />
              </div>
            </>
          }
        </div>
      </>
    )
  }

}
