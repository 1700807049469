import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import BaseTable from "./BaseTable"

import View from "./cells/ViewCell";
import Edit from "./cells/EditCell";
import Delete from "./cells/DeleteCell";

import Invoice from "../modals/Invoice";
import ConfirmationModal from "../modals/Confirmation";

import Currency from "../../../utils/Currency";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

const STATUS_FILTER = {
  name: {
    api: 'status',
    display: 'Status',
  },
  endpoint: {
    url:`${window.Api.Statuses}`,
    filters: '',
    orderBy: 'name',
    accessor: ''
  }
}

const SOURCE_FILTER = {
  name: {
    api: 'source',
    display: 'Source',
  },
  endpoint: {
    url:`${window.Api.Sources}`,
    filters: '',
    orderBy: 'name',
    accessor: ''
  }
}

const FILTERS = [STATUS_FILTER, SOURCE_FILTER]

class Invoices extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      company: props.company
    }

    this.table = React.createRef()
  }

  _getColumns(){
    let columns = [
      {
        Header: 'Date',
        id: 'created_at',
        accessor: company => moment(company.created_at).format('DD MMM YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Reference',
        id: 'reference',
        Cell: rowInfo => {
          let invoice = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a className="text-dark fw-bolder text-hover-primary fs-6">{invoice.reference}</a>
              </div>
            </div>
          )
        }
      },
      {
        Header: '# of Transactions',
        id: 'transactions_no',
        Cell: rowInfo => {
          let invoice = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a className="text-dark text-hover-primary fs-6">{invoice.transactions_no}</a>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Total',
        id: 'total',
        Cell: rowInfo => {
          let invoice = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a className="text-dark text-hover-primary fs-6">{Currency.format(invoice.currency.symbol, invoice.total)}</a>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Status',
        id: 'status',
        width: 160,
        Cell: rowInfo => {
          let invoice = rowInfo.original

          let label = invoice.status.toUpperCase()
          let className = "badge-light-dark"

          if(invoice.status === "issued"){
            className = "badge-light-success"
          }else if(invoice.status === "un_collectable"){
            className = "badge-light-danger"
          }else if(invoice.status === "processing"){
            className = "badge-light-warning"
          }

          return (
            <span className={`badge ${className} badge-sm fs-base m-lg-auto`}>
							{ label }
						</span>
          )
        }
      },
      {
        Header: 'Due Date',
        id: 'due_at',
        accessor: invoice => moment(invoice.due_at).format('DD MMM YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Payment Status',
        id: 'payment_status',
        width: 160,
        Cell: rowInfo => {
          let invoice = rowInfo.original

          let label = invoice.payment_status?.toUpperCase() || ''
          let className = "badge-light-dark"

          if(invoice.payment_status === "complete"){
            className = "badge-light-success"
          }else if(invoice.payment_status === "failed"){
            className = "badge-light-danger"
          }else if(invoice.payment_status === "processing"){
            className = "badge-light-warning"
          }

          return (
            <span className={`badge ${className} badge-sm fs-base m-lg-auto`}>
							{ label }
						</span>
          )
        }
      },
      {
        Header: 'Paid Date',
        id: 'paid_at',
        accessor: invoice => invoice.paid_at ? moment(invoice.paid_at).format('DD MMM YYYY') : '-',
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let invoice = rowInfo.original
          return (
            <div className={"my-auto float-end"}>
              {
                invoice.file &&
                <View
                  onViewClicked={() => {
                    window.open(invoice.file.url, '_blank')
                  }}
                />
              }
              {
                invoice.status === 'draft' &&
                <Edit
                  onEditClicked={() => {
                    this.setState({
                      selectedInvoice: invoice,
                      showInvoiceModal: true
                    })
                  }}
                />
              }
              {
                (invoice.status === 'draft' || invoice.status === 'un_collectable') &&
                <Delete
                  tooltip={"Delete"}
                  iconClass={"la-trash"}
                  onDeleteClicked={() => {
                    this.setState({
                      showConfirmationModal: true,
                      selectedInvoice: invoice,
                    })
                  }}
                />
              }
            </div>
          )
        }
      }
    ]

    return columns
  }

  _deleteInvoice() {
    let { selectedInvoice } = this.state

    this.setState({loading: true})

    Backend.deleteInvoice(selectedInvoice)
      .then((response) => {
        Notify.success('Invoice has been deleted')
        this.table.current.refresh()
        this.setState({
          loading: false,
          selectedInvoice: null,
          showConfirmationModal: false,
        })
      })
      .catch((error) => {
        this.setState({
          loading: false,
        })
        Notify.error(error.message)
      })
  }

  _renderConfirmationModal() {
    let { showConfirmationModal } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={"Are you sure?"}
        body={" This action can't be undone."}
        confirmTitleText={"Delete"}
        deleteTheme={"danger"}
        loading={this.state.loading}
        onConfirm={() => {
          this._deleteInvoice()
        }}
        onHide={() => {
          this.setState({
            selectedInvoice: null,
            showConfirmationModal: false,
          })
        }}
      />
    )
  }

  render() {
    let {
      company,
      selectedInvoice,
      showInvoiceModal
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Invoices}?company_id=${company.id}`}
          objects={'active'}
          noDataMessage={"No invoices found"}
          title={"Invoices"}
          showSearch={false}
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => (
            <>
              <button
                type="button"
                className="btn btn-primary ms-5"
                onClick={() => this.setState({showInvoiceModal: true})}
              >
                + Create Invoice
              </button>
            </>
          )}
        />
        {
          showInvoiceModal &&
          <Invoice
            show={showInvoiceModal}
            company={company}
            invoice={selectedInvoice}
            onHide={() => this.setState({showInvoiceModal: false, selectedInvoice: null})}
            onCreated={() => {
              this.table.current.refresh()
              this.setState({showInvoiceModal: false, selectedInvoice: null})
            }}
          />
        }
        {this._renderConfirmationModal()}
      </>
    )

  }

}

export default withRouter(Invoices);
