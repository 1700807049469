import React from "react"

import ContentLoader from "react-content-loader";

import Backend from '../../../utils/Backend'
import Notify from "../../../utils/Notify"
import ImageCropper from "../modals/ImageCropper";

export default class ImageEdit extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      image: props.image,
      showImagePicker: false,
      imagePicker: props.imagePicker,
      aspectRatio: props.aspectRatio
    }

    this.imageCropperModal = React.createRef()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _onImageChange(e) {

    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);
    let file = filesArr[0]

    if(!this.props.cropImage){
      this._uploadImageFile(file)
      return
    }

    let url = URL.createObjectURL(file)
    this._setupCropper(url, file.type, file.name)
  }

  _setupCropper(url, type, name){
    let {
      aspectRatio
    } = this.state
    this.imageCropperModal.current.cropImage(url, type, name, {
      aspectRatio
    })
  }

  _uploadImageFile(file, imageData){
    this.setState({ loading: true })
    Backend.uploadImage(file, imageData)
      .then(image => {
        this.setState({ loading: false })
        this.props.onUpdated(image)
      })
      .catch(error => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _renderLoading(imageStyle){
    let style = { ...imageStyle }

    if(this.props.hidePreview){
      return null
    }

    if(style.height == 120){
      style.height += 2
    }
    else{
      style.height += 6
    }

    return (
      <ContentLoader speed={0.5} style={style}>
        <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
      </ContentLoader>
    )
  }

  _renderImageOptions(imageStyle, loading){
    let {
      image,
      imagePicker
    } = this.state

    if(loading){
      return this._renderLoading(imageStyle)
    }

    let icon = image ? "bi-pencil-fill" : "bi-plus"

    return (
      <>
        <label
          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
          data-kt-image-input-action="change"
          data-bs-toggle="tooltip"
          title=""
          data-bs-original-title="Change avatar"
        >
          <i className={`bi ${icon} fs-7`}/>
          <input
            type="file"
            name="avatar"
            accept=".png, .jpg, .jpeg"
            onChange={e => this._onImageChange(e)}
          />
          <input type="hidden" name="avatar_remove"/>
        </label>
        {
          (this.props.deletable && this.state.image) &&
          <span
            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
            data-kt-image-input-action="remove"
            data-bs-toggle="tooltip"
            title=""
            data-bs-original-title="Remove avatar"
            onClick={e => {
              this.props.onDeleteClicked()
            }}
          >
            <i className="bi bi-x fs-2" style={{color: 'red'}}/>
          </span>
        }
      </>
    )
  }

  render() {

    let {
      image,
      loading,
      showImagePicker
    } = this.state

    let url = image ? image : null

    let size = "w-125px h-125px"

    let imageStyle = {
      backgroundColor: "#F3F6F9",
      backgroundImage: `url("${url}")`,
      width: 125,
      height: 125
    }

    if (this.props.aspectRatio) {
      imageStyle.width = (this.props.aspectRatio) * (imageStyle.width);
    }

    if(this.props.type === "banner"){
      imageStyle.width = 800
      imageStyle.height = 200
      size = "w-800px h-200px"
    }

    if(this.props.type === "portrait"){
      imageStyle.width = 250
      imageStyle.height = 400
      size = "w-250px h-400px"
    }

    if(loading){
      imageStyle.backgroundImage = null
      return this._renderLoading(imageStyle)
    }

    return (
      <>
        <div
          className="image-input image-input-outline"
        >
          <div
            className={`image-input-wrapper ${size}`}
            style={imageStyle}
          />
          {this._renderImageOptions(imageStyle, loading)}
        </div>
        <ImageCropper
          ref={this.imageCropperModal}
          onCropped={(imageFile, imageData) => this._uploadImageFile(imageFile, imageData)}
        />
      </>
    )
  }
}

ImageEdit.defaultProps = {
  type: "photo",
  deletable: false,
  hidePreview: true,
  imagePicker: false,
  cropImage: false,
  aspectRatio: 1,
}
