import AuthManager from './AuthManager'

export default class Permissions {

  static FILINGS = 'filings'
  static RECEIPTS = 'receipts'
  static TRANSACTIONS = 'transactions'
  static INTEGRATIONS = 'integrations'
  static ROLES = 'roles'
  static MESSAGES = 'messages'
  static DOCUMENTS = 'documents'
  static REPORTS = 'reports'
  static PAYMENTS = 'payments'
  static TAXES = 'taxes'


  static DEFAULT_ROLE = {
    name: null,
    agent: true,
    default: false,
    permissions: {
      filings: [],
      receipts: [],
      transactions: [],
      integrations: [],
      team_members: [],
      roles: [],
      agents: [],
      messages: [],
      documents: [],
      reports: [],
      payments: [],
      taxes: []
    },
  }

  static PERMISSION_TYPES = {
    none: null,
    view: 'view',
    edit: 'edit',
    delete: 'delete',
    create: 'create',
    view_create: 'view,create',
    view_edit: 'view,edit',
    view_create_edit: 'view,create,edit',
    view_create_edit_delete: 'view,create,edit,delete',
  }

  static NONE_OPTION = {
    label: 'None',
    value: Permissions.PERMISSION_TYPES.none,
  }

  static OPTIONS = [
    {
      label: 'Transactions',
      color: 'badge-danger',
      key: Permissions.TRANSACTIONS,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'View',
          value: Permissions.PERMISSION_TYPES.view,
        },
        {
          label: 'Digitise',
          value: Permissions.PERMISSION_TYPES.create,
        },
        {
          label: 'View and Digitise',
          value: Permissions.PERMISSION_TYPES.view_create,
        },
      ],
    },
    {
      label: 'Filings',
      color: 'badge-danger',
      key: Permissions.FILINGS,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'View',
          value: Permissions.PERMISSION_TYPES.view,
        },
        {
          label: 'View and Manage',
          value: Permissions.PERMISSION_TYPES.view_create_edit,
        },
      ],
    },
    {
      label: 'Payments',
      color: 'badge-danger',
      key: Permissions.PAYMENTS,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'View',
          value: Permissions.PERMISSION_TYPES.view,
        },
        {
          label: 'View & Manage',
          value: Permissions.PERMISSION_TYPES.view_create_edit,
        },
      ],
    },
    {
      label: 'Tax Number',
      color: 'badge-danger',
      key: Permissions.TAXES,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'View',
          value: Permissions.PERMISSION_TYPES.view,
        },
        {
          label: 'View and Manage',
          value: Permissions.PERMISSION_TYPES.view_create_edit_delete,
        },
      ],
    },
    {
      label: 'Documents',
      color: 'badge-warning',
      key: Permissions.DOCUMENTS,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'View',
          value: Permissions.PERMISSION_TYPES.view,
        },
        {
          label: 'Create',
          value: Permissions.PERMISSION_TYPES.create,
        },
        {
          label: 'View and Manage',
          value: Permissions.PERMISSION_TYPES.view_create_edit_delete,
        },
      ],
    },
    {
      label: 'Reports',
      color: 'badge-warning',
      key: Permissions.REPORTS,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'View',
          value: Permissions.PERMISSION_TYPES.view,
        },
        {
          label: 'View and Manage',
          value: Permissions.PERMISSION_TYPES.view_create_edit_delete,
        },
      ],
    },
    {
      label: 'Integrations',
      color: 'badge-warning',
      key: Permissions.INTEGRATIONS,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'View',
          value: Permissions.PERMISSION_TYPES.view,
        },
      ],
    },
    {
      label: 'Messages',
      color: 'badge-success',
      key: Permissions.MESSAGES,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'View',
          value: Permissions.PERMISSION_TYPES.view,
        },
        {
          label: 'View and Manage',
          value: Permissions.PERMISSION_TYPES.view_create_edit_delete,
        },
      ],
    },
  ]

  static hasViewPermission(key) {
    if (!key) {
      return false
    }
    if(AuthManager.currentUser.user.role === "admin"){
      return true
    }
    let { permissions } = AuthManager.currentUser.role
    let permission = permissions[key]
    if (!permission) {
      return false
    }
    return permission.includes('view') === true
  }

  static hasCreatePermission(key) {
    if(AuthManager.currentUser.user.role === "admin"){
      return true
    }
    let { permissions } = AuthManager.currentUser.role
    let permission = permissions[key]
    if (!permission) {
      return false
    }
    return permission.includes('create') === true
  }

  static hasEditPermission(key) {
    if(AuthManager.currentUser.user.role === "admin"){
      return true
    }
    let { permissions } = AuthManager.currentUser.role
    let permission = permissions[key]
    if (!permission) {
      return false
    }
    return permission.includes('edit') === true
  }

  static hasDeletePermission(key) {
    if(AuthManager.currentUser.user.role === "admin"){
      return true
    }
    let { permissions } = AuthManager.currentUser.role
    let permission = permissions[key]
    if (!permission) {
      return false
    }
    return permission.includes('delete') === true
  }
}
