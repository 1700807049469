import Currency from "./Currency";
import General from "./General";

export default class Calculations {

  static lineItemTotal(priceExcludingVat, vat){
    let value = parseFloat(priceExcludingVat) + (parseFloat(priceExcludingVat) * vat)
    return !value ? '0.00' : Currency.format('', value)
  }

  static totalExcludingVat(quantity, priceExcludingVat){
    let value = (quantity * (priceExcludingVat))
    return !value ? '0.00' : value.toFixed(2)
  }

  static totalVat(quantity, priceExcludingVat, vat){
    let value = (quantity * ((priceExcludingVat) * vat))
    return !value ? '0.00' : value.toFixed(2)
  }

  static totalIncludingVat(quantity, priceExcludingVat, vat){
    let value = parseFloat(Calculations.totalExcludingVat(quantity, priceExcludingVat)) + parseFloat(Calculations.totalVat(quantity, priceExcludingVat, vat))
    return !value ? '0.00' : value.toFixed(2)
  }

  static itemsSubTotal(items){
    let subTotal = 0;
    items.map(item => {
      subTotal += parseFloat(Calculations.totalExcludingVat(item.quantity, item.unit_subtotal))
    })
    return !subTotal ? '0.00' : subTotal.toFixed(2)
  }

  static itemsVatTotal(items){
    let vat = 0;
    items.map(item => {
      let itemVat = item.taxes.length > 0 ? parseFloat(item.taxes[0].rate) : 0
      vat += parseFloat(Calculations.totalVat(item.quantity, item.unit_subtotal, itemVat))
    })
    return !vat ? '0.00' : vat.toFixed(2)
  }

  static itemsTotal(items, vat){
    let total = 0;
    items.map(item => {
      total += parseFloat(Calculations.totalExcludingVat(item.quantity, item.unit_subtotal))
    })
    total += vat ? parseFloat(vat) : 0
    return !total ? '0.00' : total.toFixed(2)
  }

  static getPercentage(total, subtotal){
    let value = (total - subtotal)/subtotal * 100
    return value
  }

  static packageTotalIncludingVat(priceExcludingVat, vat, formatted = true){
    let value = Calculations.parseNumber(priceExcludingVat) + (Calculations.parseNumber(priceExcludingVat) * Calculations.parseNumber(vat))
    return !value ? '0.00' : formatted ? Currency.format('', value) : value
  }

  static packageTotalVat(priceExcludingVat, vat, formatted = true){
    let value = (Calculations.parseNumber(priceExcludingVat) * Calculations.parseNumber(vat))
    return !value ? '0.00' : formatted ? Currency.format('', value) : value
  }

  static parseNumber(value, locale = navigator.language) {
    if(!value){
      return 0
    }

    const example = Intl.NumberFormat(locale).format('1.1');
    const cleanPattern = new RegExp(`[^-+0-9${ example.charAt( 1 ) }]`, 'g');
    const cleaned = value.toString().replace(cleanPattern, '');
    const normalized = cleaned.replace(example.charAt(1), '.');

    return parseFloat(normalized);
  }

  static invoiceSubtotal(lineItems){
    let subTotal = 0;
    lineItems.map(item => {
      subTotal += parseFloat(item.subtotal)
    })
    return !subTotal ? '0.00' : subTotal.toFixed(2)
  }

  static invoiceTax(subtotal, taxRate){
    return !taxRate ? '0:00' : parseFloat(subtotal * taxRate.rate).toFixed(2)
  }

  static invoiceTotal(subtotal, tax){
    return !tax ? subtotal : parseFloat(subtotal) + parseFloat(tax)
  }

}
