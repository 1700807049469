import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import BaseTable from "./BaseTable"
import LineItems from "./LineItems"
import General from "../../../utils/General";
import UndoCell from "./cells/UndoCell";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import Currency from "../../../utils/Currency";

const STATUS_FILTER = {
  name: {
    api: 'status',
    display: 'Status',
  },
  endpoint: {
    url:`${window.Api.Statuses}`,
    filters: '',
    orderBy: 'name',
    accessor: ''
  }
}

const SOURCE_FILTER = {
  name: {
    api: 'source',
    display: 'Source',
  },
  endpoint: {
    url:`${window.Api.Sources}`,
    filters: '',
    orderBy: 'name',
    accessor: ''
  }
}

const FILTERS = [STATUS_FILTER, SOURCE_FILTER]

class Revisions extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }

    this.table = React.createRef()
  }

  _getColumns(){
    let columns = [
      {
        Header: 'Date',
        id: 'record.created_at',
        type: "date",
        format: "%d %b %Y",
        Cell: rowInfo => {
          let adjustment = rowInfo.original
          return (
            <div class="d-flex align-items-center">
							<span class="text-dark text-hover-primary fs-7">
                {moment(adjustment.record.created_at).format('DD MMM YYYY')}
              </span>
						</div>
          )
        }
      },
      {
        Header: 'Amount',
        id: 'total',
        Cell: rowInfo => {
          let adjustment = rowInfo.original
          let symbol = adjustment.record.currency.symbol
          return (
            <div class="d-flex align-items-center">
							<a class="text-dark fw-bolder text-hover-primary fs-6">{Currency.format(symbol, adjustment.record.total)}</a>
						</div>
          )
        }
      },
      {
        Header: 'Tax',
        id: 'tax',
        width: 80,
        Cell: rowInfo => {
          let adjustment = rowInfo.original
          let symbol = adjustment.record.currency.symbol
          return (
            <div class="d-flex align-items-center">
							<a class="text-dark fw-bolder text-hover-primary fs-6">{Currency.format(symbol, adjustment.record.tax)}</a>
						</div>
          )
        }
      },
      {
        Header: 'Issued At',
        id: 'record.issued_at',
        accessor: adjustment => moment(adjustment.record.issued_at).format('DD MMM YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Customer/Supplier',
        id: 'name',
        width: 200,
        accessor: 'name',
        Cell: rowInfo => {
          let adjustment = rowInfo.original
          let entity = adjustment.record.entity
          let title = entity?.first_name ? entity.first_name : "-"
          let subtitle = entity?.email || ""
          if(entity?.last_name){
            title += " " + entity.last_name
          }
          else if(entity?.type === "pos_terminal"){
            title = "POS Terminal"
          }
          return (
            <div class="d-flex align-items-center">
              <div class="d-flex justify-content-start flex-column">
                <a class="text-dark fw-bolder text-hover-primary fs-6">{title}</a>
                <span class="text-muted fw-bold text-muted d-block fs-7">{subtitle}</span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Type',
        Cell: rowInfo => {
          let adjustment = rowInfo.original
          let type = adjustment.record.type.toUpperCase()
          let className = adjustment.record.type = "sale" ? "success" : "warning"
          return (
            <span class={`badge badge-light-${className} badge-sm fs-base m-lg-auto`}>
							{ type }
						</span>
          )
        }
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let adjustment = rowInfo.original
          return (
            <div className={"my-auto float-end"}>
              <UndoCell
                tooltip={"Undo"}
                onUndoClicked={() => {
                  this._deleteAdjustment(adjustment)
                }}
              />
            </div>
          )
        }
      }
    ]

    return columns
  }

  _deleteAdjustment(adjustment){
    Backend.deleteAdjustment(adjustment).then(response => {
      Notify.success("Revision Un-done")
      this.table.current.refresh()
    }).catch(e => Notify.error(e.message))
  }

  _deleteRevision(revisionId){
    Backend.deleteRevision(revisionId).then(response => {
      Notify.success("Revisions Deleted")
      this.props.onRevisionDeleted()
    }).catch(e => Notify.error(e.message))
  }

  _commitChanges(){
    let data = {
      id: this.props.revisionId,
      filing: this.props.filing.id,
      status: "committed"
    }

    this.setState({loading: true})

    Backend.updateRevision(data).then(response => {
      Notify.success("Revisions Committed")
      this.props.onRevisionCommitted()
      this.setState({loading: false})
    }).catch(e => {
      Notify.error(e.message)
      this.setState({loading: false})
    })
  }

  render() {
    let {

    } = this.state

    const columns = this._getColumns()

    if(!this.props.revisionId){
      return null
    }

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Adjustments}?revision_id=${this.props.revisionId}&type=allocate`}
          noDataMessage={"No transactions found"}
          title={"Allocate Transactions"}
          columns={columns}
          objects={'active'}
          //filters={FILTERS}
          showSearch={false}
          dateRange={true}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
        />
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Adjustments}?revision_id=${this.props.revisionId}&type=unallocate`}
          noDataMessage={"No transactions found"}
          title={"Remove Transactions"}
          columns={columns}
          objects={'active'}
          //filters={FILTERS}
          showSearch={false}
          dateRange={true}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
        />
        <div className="row justify-content-between mb-10">
          <div className="col">
            <button
              className="btn btn-outline-danger"
              onClick={e => this._deleteRevision(this.props.revisionId)}
            >
              Delete Changes
            </button>
          </div>
          <div className="col text-end">
            <button
              className="btn btn-primary ms-auto"
              disabled={this.state.loading}
              onClick={e => this._commitChanges()}
            >
              Save Changes
            </button>
          </div>
        </div>
      </>
    )

  }

}

Revisions.defaultProps = {
  title: "Revisions",
  allocated: true
}

export default withRouter(Revisions);
